// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/advisors_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AccountStub, Advisor, Attachment, ExternalLinks, FamilyStub, UploadAttachment } from "./common_pb.ts";
import { EmailMessage, PhoneCall, TextMessage } from "./conversation_pb.ts";
import { ContentUpdates, Note } from "./notes_pb.ts";
import { Task } from "./tasks_pb.ts";
import { SecretStub } from "./secrets_pb.ts";

/**
 * @generated from enum protogen.FamilyStatus
 */
export enum FamilyStatus {
  /**
   * @generated from enum value: NOT_SET = 0;
   */
  NOT_SET = 0,

  /**
   * @generated from enum value: PROSPECT = 1;
   */
  PROSPECT = 1,

  /**
   * @generated from enum value: ACTIVE = 2;
   */
  ACTIVE = 2,

  /**
   * @generated from enum value: DEACTIVATED = 3;
   */
  DEACTIVATED = 3,

  /**
   * @generated from enum value: TEST = 4;
   */
  TEST = 4,

  /**
   * @generated from enum value: DEMO = 5;
   */
  DEMO = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(FamilyStatus)
proto3.util.setEnumType(FamilyStatus, "protogen.FamilyStatus", [
  { no: 0, name: "NOT_SET" },
  { no: 1, name: "PROSPECT" },
  { no: 2, name: "ACTIVE" },
  { no: 3, name: "DEACTIVATED" },
  { no: 4, name: "TEST" },
  { no: 5, name: "DEMO" },
]);

/**
 * @generated from enum protogen.Medium
 */
export enum Medium {
  /**
   * @generated from enum value: MEDIUM_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MEDIUM_SMS = 1;
   */
  SMS = 1,

  /**
   * @generated from enum value: MEDIUM_EMAIL = 2;
   */
  EMAIL = 2,

  /**
   * @generated from enum value: MEDIUM_PHONE = 3;
   */
  PHONE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Medium)
proto3.util.setEnumType(Medium, "protogen.Medium", [
  { no: 0, name: "MEDIUM_UNSPECIFIED" },
  { no: 1, name: "MEDIUM_SMS" },
  { no: 2, name: "MEDIUM_EMAIL" },
  { no: 3, name: "MEDIUM_PHONE" },
]);

/**
 * @generated from message protogen.Member
 */
export class Member extends Message<Member> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  /**
   * @generated from field: string displayName = 4;
   */
  displayName = "";

  /**
   * @generated from field: string avatarUrl = 5;
   */
  avatarUrl = "";

  /**
   *  Lets see how much we can limit distribution of this data
   *
   * @generated from field: string primaryPhone = 6;
   */
  primaryPhone = "";

  /**
   * @generated from field: string primaryEmail = 7;
   */
  primaryEmail = "";

  /**
   * @generated from field: repeated string alternateEmails = 8;
   */
  alternateEmails: string[] = [];

  constructor(data?: PartialMessage<Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Member";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "displayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "avatarUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "primaryPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "primaryEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "alternateEmails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Member {
    return new Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Member {
    return new Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Member {
    return new Member().fromJsonString(jsonString, options);
  }

  static equals(a: Member | PlainMessage<Member> | undefined, b: Member | PlainMessage<Member> | undefined): boolean {
    return proto3.util.equals(Member, a, b);
  }
}

/**
 * @generated from message protogen.Family
 */
export class Family extends Message<Family> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string avatarUrl = 3;
   */
  avatarUrl = "";

  /**
   * @generated from field: repeated protogen.Member familyMembers = 4;
   */
  familyMembers: Member[] = [];

  /**
   * @generated from field: string location = 5;
   */
  location = "";

  /**
   * @generated from field: protogen.FamilyStatus status = 6;
   */
  status = FamilyStatus.NOT_SET;

  /**
   * @generated from field: string advisorRef = 7;
   */
  advisorRef = "";

  constructor(data?: PartialMessage<Family>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Family";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "avatarUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "familyMembers", kind: "message", T: Member, repeated: true },
    { no: 5, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(FamilyStatus) },
    { no: 7, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Family {
    return new Family().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Family {
    return new Family().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Family {
    return new Family().fromJsonString(jsonString, options);
  }

  static equals(a: Family | PlainMessage<Family> | undefined, b: Family | PlainMessage<Family> | undefined): boolean {
    return proto3.util.equals(Family, a, b);
  }
}

/**
 * @generated from message protogen.Conversation
 */
export class Conversation extends Message<Conversation> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string familyRef = 2;
   */
  familyRef = "";

  /**
   * enum???
   *
   * @generated from field: protogen.Medium medium = 3;
   */
  medium = Medium.UNSPECIFIED;

  /**
   * @generated from field: int64 lastUpdateSec = 4;
   */
  lastUpdateSec = protoInt64.zero;

  /**
   * @generated from field: repeated protogen.Member members = 5;
   */
  members: Member[] = [];

  constructor(data?: PartialMessage<Conversation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Conversation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "medium", kind: "enum", T: proto3.getEnumType(Medium) },
    { no: 4, name: "lastUpdateSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "members", kind: "message", T: Member, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Conversation {
    return new Conversation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Conversation {
    return new Conversation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Conversation {
    return new Conversation().fromJsonString(jsonString, options);
  }

  static equals(a: Conversation | PlainMessage<Conversation> | undefined, b: Conversation | PlainMessage<Conversation> | undefined): boolean {
    return proto3.util.equals(Conversation, a, b);
  }
}

/**
 * @generated from message protogen.AttachmentContext
 */
export class AttachmentContext extends Message<AttachmentContext> {
  /**
   * @generated from field: protogen.Attachment attachment = 1;
   */
  attachment?: Attachment;

  /**
   * @generated from field: int64 timestampSec = 2;
   */
  timestampSec = protoInt64.zero;

  /**
   * @generated from field: protogen.Medium sourceMedium = 3;
   */
  sourceMedium = Medium.UNSPECIFIED;

  /**
   * @generated from field: string sourceRef = 4;
   */
  sourceRef = "";

  constructor(data?: PartialMessage<AttachmentContext>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AttachmentContext";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "attachment", kind: "message", T: Attachment },
    { no: 2, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "sourceMedium", kind: "enum", T: proto3.getEnumType(Medium) },
    { no: 4, name: "sourceRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AttachmentContext {
    return new AttachmentContext().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AttachmentContext {
    return new AttachmentContext().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AttachmentContext {
    return new AttachmentContext().fromJsonString(jsonString, options);
  }

  static equals(a: AttachmentContext | PlainMessage<AttachmentContext> | undefined, b: AttachmentContext | PlainMessage<AttachmentContext> | undefined): boolean {
    return proto3.util.equals(AttachmentContext, a, b);
  }
}

/**
 * @generated from message protogen.ListAdvisorFamiliesRequest
 */
export class ListAdvisorFamiliesRequest extends Message<ListAdvisorFamiliesRequest> {
  constructor(data?: PartialMessage<ListAdvisorFamiliesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAdvisorFamiliesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAdvisorFamiliesRequest {
    return new ListAdvisorFamiliesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAdvisorFamiliesRequest {
    return new ListAdvisorFamiliesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAdvisorFamiliesRequest {
    return new ListAdvisorFamiliesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAdvisorFamiliesRequest | PlainMessage<ListAdvisorFamiliesRequest> | undefined, b: ListAdvisorFamiliesRequest | PlainMessage<ListAdvisorFamiliesRequest> | undefined): boolean {
    return proto3.util.equals(ListAdvisorFamiliesRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListAdvisorFamiliesResponse
 */
export class ListAdvisorFamiliesResponse extends Message<ListAdvisorFamiliesResponse> {
  /**
   * @generated from field: repeated protogen.Family families = 1;
   */
  families: Family[] = [];

  constructor(data?: PartialMessage<ListAdvisorFamiliesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAdvisorFamiliesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "families", kind: "message", T: Family, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAdvisorFamiliesResponse {
    return new ListAdvisorFamiliesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAdvisorFamiliesResponse {
    return new ListAdvisorFamiliesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAdvisorFamiliesResponse {
    return new ListAdvisorFamiliesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAdvisorFamiliesResponse | PlainMessage<ListAdvisorFamiliesResponse> | undefined, b: ListAdvisorFamiliesResponse | PlainMessage<ListAdvisorFamiliesResponse> | undefined): boolean {
    return proto3.util.equals(ListAdvisorFamiliesResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorDashboardRequest
 */
export class FetchAdvisorDashboardRequest extends Message<FetchAdvisorDashboardRequest> {
  constructor(data?: PartialMessage<FetchAdvisorDashboardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorDashboardRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorDashboardRequest {
    return new FetchAdvisorDashboardRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorDashboardRequest {
    return new FetchAdvisorDashboardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorDashboardRequest {
    return new FetchAdvisorDashboardRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorDashboardRequest | PlainMessage<FetchAdvisorDashboardRequest> | undefined, b: FetchAdvisorDashboardRequest | PlainMessage<FetchAdvisorDashboardRequest> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorDashboardRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorDashboardResponse
 */
export class FetchAdvisorDashboardResponse extends Message<FetchAdvisorDashboardResponse> {
  /**
   * @generated from field: repeated protogen.Family families = 1;
   */
  families: Family[] = [];

  /**
   * @generated from field: repeated protogen.FetchAdvisorDashboardResponse.FamilyStatus familyStatus = 2;
   */
  familyStatus: FetchAdvisorDashboardResponse_FamilyStatus[] = [];

  constructor(data?: PartialMessage<FetchAdvisorDashboardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorDashboardResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "families", kind: "message", T: Family, repeated: true },
    { no: 2, name: "familyStatus", kind: "message", T: FetchAdvisorDashboardResponse_FamilyStatus, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorDashboardResponse {
    return new FetchAdvisorDashboardResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorDashboardResponse {
    return new FetchAdvisorDashboardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorDashboardResponse {
    return new FetchAdvisorDashboardResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorDashboardResponse | PlainMessage<FetchAdvisorDashboardResponse> | undefined, b: FetchAdvisorDashboardResponse | PlainMessage<FetchAdvisorDashboardResponse> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorDashboardResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorDashboardResponse.FamilyStatus
 */
export class FetchAdvisorDashboardResponse_FamilyStatus extends Message<FetchAdvisorDashboardResponse_FamilyStatus> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: int32 unreadMessages = 2;
   */
  unreadMessages = 0;

  /**
   * @generated from field: int32 unreadEmails = 3;
   */
  unreadEmails = 0;

  /**
   * @generated from field: int32 missedCalls = 4;
   */
  missedCalls = 0;

  /**
   * @generated from field: int32 openTasks = 5;
   */
  openTasks = 0;

  /**
   * @generated from field: int64 lastActivitySec = 8;
   */
  lastActivitySec = protoInt64.zero;

  constructor(data?: PartialMessage<FetchAdvisorDashboardResponse_FamilyStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorDashboardResponse.FamilyStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unreadMessages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "unreadEmails", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "missedCalls", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "openTasks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "lastActivitySec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorDashboardResponse_FamilyStatus {
    return new FetchAdvisorDashboardResponse_FamilyStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorDashboardResponse_FamilyStatus {
    return new FetchAdvisorDashboardResponse_FamilyStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorDashboardResponse_FamilyStatus {
    return new FetchAdvisorDashboardResponse_FamilyStatus().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorDashboardResponse_FamilyStatus | PlainMessage<FetchAdvisorDashboardResponse_FamilyStatus> | undefined, b: FetchAdvisorDashboardResponse_FamilyStatus | PlainMessage<FetchAdvisorDashboardResponse_FamilyStatus> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorDashboardResponse_FamilyStatus, a, b);
  }
}

/**
 * @generated from message protogen.ListAllAdvisorsRequest
 */
export class ListAllAdvisorsRequest extends Message<ListAllAdvisorsRequest> {
  constructor(data?: PartialMessage<ListAllAdvisorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllAdvisorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllAdvisorsRequest {
    return new ListAllAdvisorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllAdvisorsRequest {
    return new ListAllAdvisorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllAdvisorsRequest {
    return new ListAllAdvisorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllAdvisorsRequest | PlainMessage<ListAllAdvisorsRequest> | undefined, b: ListAllAdvisorsRequest | PlainMessage<ListAllAdvisorsRequest> | undefined): boolean {
    return proto3.util.equals(ListAllAdvisorsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListAllAdvisorsResponse
 */
export class ListAllAdvisorsResponse extends Message<ListAllAdvisorsResponse> {
  /**
   * @generated from field: repeated protogen.Advisor advisors = 1;
   */
  advisors: Advisor[] = [];

  constructor(data?: PartialMessage<ListAllAdvisorsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllAdvisorsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisors", kind: "message", T: Advisor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllAdvisorsResponse {
    return new ListAllAdvisorsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllAdvisorsResponse {
    return new ListAllAdvisorsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllAdvisorsResponse {
    return new ListAllAdvisorsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllAdvisorsResponse | PlainMessage<ListAllAdvisorsResponse> | undefined, b: ListAllAdvisorsResponse | PlainMessage<ListAllAdvisorsResponse> | undefined): boolean {
    return proto3.util.equals(ListAllAdvisorsResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyRequest
 */
export class CreateFamilyRequest extends Message<CreateFamilyRequest> {
  /**
   * @generated from field: string familyName = 1;
   */
  familyName = "";

  /**
   * @generated from field: string location = 2;
   */
  location = "";

  /**
   * @generated from field: string advisorRef = 3;
   */
  advisorRef = "";

  /**
   * @generated from field: protogen.FamilyStatus status = 4;
   */
  status = FamilyStatus.NOT_SET;

  /**
   * @generated from field: repeated protogen.CreateFamilyRequest.Member members = 6;
   */
  members: CreateFamilyRequest_Member[] = [];

  constructor(data?: PartialMessage<CreateFamilyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(FamilyStatus) },
    { no: 6, name: "members", kind: "message", T: CreateFamilyRequest_Member, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyRequest {
    return new CreateFamilyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyRequest {
    return new CreateFamilyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyRequest {
    return new CreateFamilyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyRequest | PlainMessage<CreateFamilyRequest> | undefined, b: CreateFamilyRequest | PlainMessage<CreateFamilyRequest> | undefined): boolean {
    return proto3.util.equals(CreateFamilyRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyRequest.Member
 */
export class CreateFamilyRequest_Member extends Message<CreateFamilyRequest_Member> {
  /**
   * @generated from field: string firstName = 1;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 2;
   */
  lastName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  /**
   * @generated from field: repeated string altEmails = 5;
   */
  altEmails: string[] = [];

  constructor(data?: PartialMessage<CreateFamilyRequest_Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyRequest.Member";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "altEmails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyRequest_Member {
    return new CreateFamilyRequest_Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyRequest_Member {
    return new CreateFamilyRequest_Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyRequest_Member {
    return new CreateFamilyRequest_Member().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyRequest_Member | PlainMessage<CreateFamilyRequest_Member> | undefined, b: CreateFamilyRequest_Member | PlainMessage<CreateFamilyRequest_Member> | undefined): boolean {
    return proto3.util.equals(CreateFamilyRequest_Member, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyResponse
 */
export class CreateFamilyResponse extends Message<CreateFamilyResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  constructor(data?: PartialMessage<CreateFamilyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyResponse {
    return new CreateFamilyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyResponse {
    return new CreateFamilyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyResponse {
    return new CreateFamilyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyResponse | PlainMessage<CreateFamilyResponse> | undefined, b: CreateFamilyResponse | PlainMessage<CreateFamilyResponse> | undefined): boolean {
    return proto3.util.equals(CreateFamilyResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyRequest
 */
export class UpdateFamilyRequest extends Message<UpdateFamilyRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string updatedName = 2;
   */
  updatedName = "";

  /**
   * @generated from field: string updatedLocation = 3;
   */
  updatedLocation = "";

  /**
   * @generated from field: string updatedAdvisorRef = 4;
   */
  updatedAdvisorRef = "";

  /**
   * @generated from field: protogen.FamilyStatus updatedStatus = 5;
   */
  updatedStatus = FamilyStatus.NOT_SET;

  /**
   * @generated from field: repeated protogen.UpdateFamilyRequest.Member updatedMembers = 6;
   */
  updatedMembers: UpdateFamilyRequest_Member[] = [];

  /**
   * @generated from field: repeated protogen.UpdateFamilyRequest.Member newMembers = 7;
   */
  newMembers: UpdateFamilyRequest_Member[] = [];

  constructor(data?: PartialMessage<UpdateFamilyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatedLocation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updatedAdvisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updatedStatus", kind: "enum", T: proto3.getEnumType(FamilyStatus) },
    { no: 6, name: "updatedMembers", kind: "message", T: UpdateFamilyRequest_Member, repeated: true },
    { no: 7, name: "newMembers", kind: "message", T: UpdateFamilyRequest_Member, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyRequest {
    return new UpdateFamilyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyRequest {
    return new UpdateFamilyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyRequest {
    return new UpdateFamilyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyRequest | PlainMessage<UpdateFamilyRequest> | undefined, b: UpdateFamilyRequest | PlainMessage<UpdateFamilyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyRequest.Member
 */
export class UpdateFamilyRequest_Member extends Message<UpdateFamilyRequest_Member> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string updatedFirstName = 2;
   */
  updatedFirstName = "";

  /**
   * @generated from field: string updatedLastName = 3;
   */
  updatedLastName = "";

  /**
   * @generated from field: string updatedEmail = 4;
   */
  updatedEmail = "";

  /**
   * @generated from field: string updatedPhone = 5;
   */
  updatedPhone = "";

  /**
   * @generated from field: bool shouldUpdateAltEmails = 6;
   */
  shouldUpdateAltEmails = false;

  /**
   * @generated from field: repeated string updatedAltEmails = 7;
   */
  updatedAltEmails: string[] = [];

  constructor(data?: PartialMessage<UpdateFamilyRequest_Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyRequest.Member";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedFirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatedLastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updatedEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updatedPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "shouldUpdateAltEmails", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "updatedAltEmails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyRequest_Member {
    return new UpdateFamilyRequest_Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyRequest_Member {
    return new UpdateFamilyRequest_Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyRequest_Member {
    return new UpdateFamilyRequest_Member().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyRequest_Member | PlainMessage<UpdateFamilyRequest_Member> | undefined, b: UpdateFamilyRequest_Member | PlainMessage<UpdateFamilyRequest_Member> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyRequest_Member, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyResponse
 */
export class UpdateFamilyResponse extends Message<UpdateFamilyResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  constructor(data?: PartialMessage<UpdateFamilyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyResponse {
    return new UpdateFamilyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyResponse {
    return new UpdateFamilyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyResponse {
    return new UpdateFamilyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyResponse | PlainMessage<UpdateFamilyResponse> | undefined, b: UpdateFamilyResponse | PlainMessage<UpdateFamilyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyResponse, a, b);
  }
}

/**
 * @generated from message protogen.FeedEntry
 */
export class FeedEntry extends Message<FeedEntry> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string senderRef = 2;
   */
  senderRef = "";

  /**
   * @generated from field: int64 timestampSec = 3;
   */
  timestampSec = protoInt64.zero;

  /**
   * @generated from field: protogen.Medium medium = 4;
   */
  medium = Medium.UNSPECIFIED;

  /**
   * oneof content ???
   *
   * @generated from field: protogen.TextMessage textMessage = 5;
   */
  textMessage?: TextMessage;

  /**
   * @generated from field: protogen.EmailMessage emailMessage = 6;
   */
  emailMessage?: EmailMessage;

  /**
   * @generated from field: protogen.PhoneCall phoneCall = 7;
   */
  phoneCall?: PhoneCall;

  /**
   * Used to differentiate which user a message was sent to.
   *
   * @generated from field: repeated string recipientRefs = 8;
   */
  recipientRefs: string[] = [];

  constructor(data?: PartialMessage<FeedEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FeedEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "senderRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "medium", kind: "enum", T: proto3.getEnumType(Medium) },
    { no: 5, name: "textMessage", kind: "message", T: TextMessage },
    { no: 6, name: "emailMessage", kind: "message", T: EmailMessage },
    { no: 7, name: "phoneCall", kind: "message", T: PhoneCall },
    { no: 8, name: "recipientRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeedEntry {
    return new FeedEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeedEntry {
    return new FeedEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeedEntry {
    return new FeedEntry().fromJsonString(jsonString, options);
  }

  static equals(a: FeedEntry | PlainMessage<FeedEntry> | undefined, b: FeedEntry | PlainMessage<FeedEntry> | undefined): boolean {
    return proto3.util.equals(FeedEntry, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyDetailsRequest
 */
export class GetFamilyDetailsRequest extends Message<GetFamilyDetailsRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  constructor(data?: PartialMessage<GetFamilyDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyDetailsRequest {
    return new GetFamilyDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyDetailsRequest {
    return new GetFamilyDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyDetailsRequest {
    return new GetFamilyDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyDetailsRequest | PlainMessage<GetFamilyDetailsRequest> | undefined, b: GetFamilyDetailsRequest | PlainMessage<GetFamilyDetailsRequest> | undefined): boolean {
    return proto3.util.equals(GetFamilyDetailsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyDetailsResponse
 */
export class GetFamilyDetailsResponse extends Message<GetFamilyDetailsResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  /**
   * @generated from field: repeated protogen.AttachmentContext attachments = 2;
   */
  attachments: AttachmentContext[] = [];

  /**
   * @generated from field: protogen.Note familyNotes = 3;
   */
  familyNotes?: Note;

  /**
   * @generated from field: repeated protogen.Task activeTasks = 4;
   */
  activeTasks: Task[] = [];

  /**
   * @generated from field: repeated protogen.SecretStub secrets = 5;
   */
  secrets: SecretStub[] = [];

  constructor(data?: PartialMessage<GetFamilyDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
    { no: 2, name: "attachments", kind: "message", T: AttachmentContext, repeated: true },
    { no: 3, name: "familyNotes", kind: "message", T: Note },
    { no: 4, name: "activeTasks", kind: "message", T: Task, repeated: true },
    { no: 5, name: "secrets", kind: "message", T: SecretStub, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyDetailsResponse {
    return new GetFamilyDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyDetailsResponse {
    return new GetFamilyDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyDetailsResponse {
    return new GetFamilyDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyDetailsResponse | PlainMessage<GetFamilyDetailsResponse> | undefined, b: GetFamilyDetailsResponse | PlainMessage<GetFamilyDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetFamilyDetailsResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyActivityRequest
 */
export class GetFamilyActivityRequest extends Message<GetFamilyActivityRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * Need to enrich this.
   *
   * @generated from field: string startCursor = 2;
   */
  startCursor = "";

  /**
   * @generated from field: bool markConversationsRead = 3;
   */
  markConversationsRead = false;

  constructor(data?: PartialMessage<GetFamilyActivityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyActivityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "startCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "markConversationsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyActivityRequest {
    return new GetFamilyActivityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyActivityRequest {
    return new GetFamilyActivityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyActivityRequest {
    return new GetFamilyActivityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyActivityRequest | PlainMessage<GetFamilyActivityRequest> | undefined, b: GetFamilyActivityRequest | PlainMessage<GetFamilyActivityRequest> | undefined): boolean {
    return proto3.util.equals(GetFamilyActivityRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyActivityResponse
 */
export class GetFamilyActivityResponse extends Message<GetFamilyActivityResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  /**
   * @generated from field: repeated protogen.Conversation conversations = 2;
   */
  conversations: Conversation[] = [];

  /**
   * @generated from field: repeated protogen.FeedEntry entries = 3;
   */
  entries: FeedEntry[] = [];

  /**
   * @generated from field: repeated protogen.AccountStub accounts = 4;
   */
  accounts: AccountStub[] = [];

  /**
   * @generated from field: string startCursor = 5;
   */
  startCursor = "";

  /**
   * @generated from field: string nextCursor = 6;
   */
  nextCursor = "";

  /**
   * @generated from field: bool hasMore = 7;
   */
  hasMore = false;

  constructor(data?: PartialMessage<GetFamilyActivityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyActivityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
    { no: 2, name: "conversations", kind: "message", T: Conversation, repeated: true },
    { no: 3, name: "entries", kind: "message", T: FeedEntry, repeated: true },
    { no: 4, name: "accounts", kind: "message", T: AccountStub, repeated: true },
    { no: 5, name: "startCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "hasMore", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyActivityResponse {
    return new GetFamilyActivityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyActivityResponse {
    return new GetFamilyActivityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyActivityResponse {
    return new GetFamilyActivityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyActivityResponse | PlainMessage<GetFamilyActivityResponse> | undefined, b: GetFamilyActivityResponse | PlainMessage<GetFamilyActivityResponse> | undefined): boolean {
    return proto3.util.equals(GetFamilyActivityResponse, a, b);
  }
}

/**
 * @generated from message protogen.NotificationRegistrationRequest
 */
export class NotificationRegistrationRequest extends Message<NotificationRegistrationRequest> {
  /**
   * @generated from field: string platform = 1;
   */
  platform = "";

  /**
   * @generated from field: string subscription = 2;
   */
  subscription = "";

  constructor(data?: PartialMessage<NotificationRegistrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.NotificationRegistrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "platform", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "subscription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationRegistrationRequest {
    return new NotificationRegistrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationRegistrationRequest {
    return new NotificationRegistrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationRegistrationRequest {
    return new NotificationRegistrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationRegistrationRequest | PlainMessage<NotificationRegistrationRequest> | undefined, b: NotificationRegistrationRequest | PlainMessage<NotificationRegistrationRequest> | undefined): boolean {
    return proto3.util.equals(NotificationRegistrationRequest, a, b);
  }
}

/**
 * @generated from message protogen.NotificationRegistrationResponse
 */
export class NotificationRegistrationResponse extends Message<NotificationRegistrationResponse> {
  constructor(data?: PartialMessage<NotificationRegistrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.NotificationRegistrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationRegistrationResponse {
    return new NotificationRegistrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationRegistrationResponse {
    return new NotificationRegistrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationRegistrationResponse {
    return new NotificationRegistrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationRegistrationResponse | PlainMessage<NotificationRegistrationResponse> | undefined, b: NotificationRegistrationResponse | PlainMessage<NotificationRegistrationResponse> | undefined): boolean {
    return proto3.util.equals(NotificationRegistrationResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetSignedUploadsUrlsRequest
 */
export class GetSignedUploadsUrlsRequest extends Message<GetSignedUploadsUrlsRequest> {
  /**
   * @generated from field: repeated protogen.GetSignedUploadsUrlsRequest.Upload uploads = 1;
   */
  uploads: GetSignedUploadsUrlsRequest_Upload[] = [];

  constructor(data?: PartialMessage<GetSignedUploadsUrlsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetSignedUploadsUrlsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "uploads", kind: "message", T: GetSignedUploadsUrlsRequest_Upload, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSignedUploadsUrlsRequest {
    return new GetSignedUploadsUrlsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsRequest {
    return new GetSignedUploadsUrlsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsRequest {
    return new GetSignedUploadsUrlsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSignedUploadsUrlsRequest | PlainMessage<GetSignedUploadsUrlsRequest> | undefined, b: GetSignedUploadsUrlsRequest | PlainMessage<GetSignedUploadsUrlsRequest> | undefined): boolean {
    return proto3.util.equals(GetSignedUploadsUrlsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetSignedUploadsUrlsRequest.Upload
 */
export class GetSignedUploadsUrlsRequest_Upload extends Message<GetSignedUploadsUrlsRequest_Upload> {
  /**
   * @generated from field: string filename = 1;
   */
  filename = "";

  /**
   * @generated from field: string contentType = 2;
   */
  contentType = "";

  /**
   * @generated from field: int32 size = 3;
   */
  size = 0;

  constructor(data?: PartialMessage<GetSignedUploadsUrlsRequest_Upload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetSignedUploadsUrlsRequest.Upload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSignedUploadsUrlsRequest_Upload {
    return new GetSignedUploadsUrlsRequest_Upload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsRequest_Upload {
    return new GetSignedUploadsUrlsRequest_Upload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsRequest_Upload {
    return new GetSignedUploadsUrlsRequest_Upload().fromJsonString(jsonString, options);
  }

  static equals(a: GetSignedUploadsUrlsRequest_Upload | PlainMessage<GetSignedUploadsUrlsRequest_Upload> | undefined, b: GetSignedUploadsUrlsRequest_Upload | PlainMessage<GetSignedUploadsUrlsRequest_Upload> | undefined): boolean {
    return proto3.util.equals(GetSignedUploadsUrlsRequest_Upload, a, b);
  }
}

/**
 * @generated from message protogen.GetSignedUploadsUrlsResponse
 */
export class GetSignedUploadsUrlsResponse extends Message<GetSignedUploadsUrlsResponse> {
  /**
   * @generated from field: repeated protogen.GetSignedUploadsUrlsResponse.SignedUrl signedUrls = 1;
   */
  signedUrls: GetSignedUploadsUrlsResponse_SignedUrl[] = [];

  /**
   * @generated from field: string validationKey = 2;
   */
  validationKey = "";

  constructor(data?: PartialMessage<GetSignedUploadsUrlsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetSignedUploadsUrlsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signedUrls", kind: "message", T: GetSignedUploadsUrlsResponse_SignedUrl, repeated: true },
    { no: 2, name: "validationKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSignedUploadsUrlsResponse {
    return new GetSignedUploadsUrlsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsResponse {
    return new GetSignedUploadsUrlsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsResponse {
    return new GetSignedUploadsUrlsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSignedUploadsUrlsResponse | PlainMessage<GetSignedUploadsUrlsResponse> | undefined, b: GetSignedUploadsUrlsResponse | PlainMessage<GetSignedUploadsUrlsResponse> | undefined): boolean {
    return proto3.util.equals(GetSignedUploadsUrlsResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetSignedUploadsUrlsResponse.SignedUrl
 */
export class GetSignedUploadsUrlsResponse_SignedUrl extends Message<GetSignedUploadsUrlsResponse_SignedUrl> {
  /**
   * @generated from field: string filename = 1;
   */
  filename = "";

  /**
   * @generated from field: string key = 2;
   */
  key = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  constructor(data?: PartialMessage<GetSignedUploadsUrlsResponse_SignedUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetSignedUploadsUrlsResponse.SignedUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSignedUploadsUrlsResponse_SignedUrl {
    return new GetSignedUploadsUrlsResponse_SignedUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsResponse_SignedUrl {
    return new GetSignedUploadsUrlsResponse_SignedUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSignedUploadsUrlsResponse_SignedUrl {
    return new GetSignedUploadsUrlsResponse_SignedUrl().fromJsonString(jsonString, options);
  }

  static equals(a: GetSignedUploadsUrlsResponse_SignedUrl | PlainMessage<GetSignedUploadsUrlsResponse_SignedUrl> | undefined, b: GetSignedUploadsUrlsResponse_SignedUrl | PlainMessage<GetSignedUploadsUrlsResponse_SignedUrl> | undefined): boolean {
    return proto3.util.equals(GetSignedUploadsUrlsResponse_SignedUrl, a, b);
  }
}

/**
 * @generated from message protogen.ActivitySubscription
 */
export class ActivitySubscription extends Message<ActivitySubscription> {
  /**
   * @generated from field: string entityType = 1;
   */
  entityType = "";

  /**
   * @generated from field: string entityRef = 2;
   */
  entityRef = "";

  /**
   * @generated from field: bool pushEnabled = 3;
   */
  pushEnabled = false;

  /**
   *  bool emailEnabled = 3;
   * nullable
   *
   * @generated from field: protogen.FamilyStub family = 5;
   */
  family?: FamilyStub;

  constructor(data?: PartialMessage<ActivitySubscription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ActivitySubscription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pushEnabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "family", kind: "message", T: FamilyStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivitySubscription {
    return new ActivitySubscription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivitySubscription {
    return new ActivitySubscription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivitySubscription {
    return new ActivitySubscription().fromJsonString(jsonString, options);
  }

  static equals(a: ActivitySubscription | PlainMessage<ActivitySubscription> | undefined, b: ActivitySubscription | PlainMessage<ActivitySubscription> | undefined): boolean {
    return proto3.util.equals(ActivitySubscription, a, b);
  }
}

/**
 * @generated from message protogen.ListActivitySubscriptionsRequest
 */
export class ListActivitySubscriptionsRequest extends Message<ListActivitySubscriptionsRequest> {
  constructor(data?: PartialMessage<ListActivitySubscriptionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListActivitySubscriptionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListActivitySubscriptionsRequest {
    return new ListActivitySubscriptionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListActivitySubscriptionsRequest {
    return new ListActivitySubscriptionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListActivitySubscriptionsRequest {
    return new ListActivitySubscriptionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListActivitySubscriptionsRequest | PlainMessage<ListActivitySubscriptionsRequest> | undefined, b: ListActivitySubscriptionsRequest | PlainMessage<ListActivitySubscriptionsRequest> | undefined): boolean {
    return proto3.util.equals(ListActivitySubscriptionsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListActivitySubscriptionsResponse
 */
export class ListActivitySubscriptionsResponse extends Message<ListActivitySubscriptionsResponse> {
  /**
   * @generated from field: repeated protogen.ActivitySubscription subscriptions = 1;
   */
  subscriptions: ActivitySubscription[] = [];

  constructor(data?: PartialMessage<ListActivitySubscriptionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListActivitySubscriptionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subscriptions", kind: "message", T: ActivitySubscription, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListActivitySubscriptionsResponse {
    return new ListActivitySubscriptionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListActivitySubscriptionsResponse {
    return new ListActivitySubscriptionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListActivitySubscriptionsResponse {
    return new ListActivitySubscriptionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListActivitySubscriptionsResponse | PlainMessage<ListActivitySubscriptionsResponse> | undefined, b: ListActivitySubscriptionsResponse | PlainMessage<ListActivitySubscriptionsResponse> | undefined): boolean {
    return proto3.util.equals(ListActivitySubscriptionsResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateAdvisorRequest
 */
export class UpdateAdvisorRequest extends Message<UpdateAdvisorRequest> {
  /**
   * @generated from field: string advisorRef = 1;
   */
  advisorRef = "";

  /**
   * @generated from field: string updatedFirstName = 2;
   */
  updatedFirstName = "";

  /**
   * @generated from field: string updatedLastName = 3;
   */
  updatedLastName = "";

  /**
   * @generated from field: string updatedPersonalEmail = 4;
   */
  updatedPersonalEmail = "";

  /**
   * @generated from field: string updatedPersonalPhone = 5;
   */
  updatedPersonalPhone = "";

  /**
   * @generated from field: string updatedCity = 6;
   */
  updatedCity = "";

  /**
   * @generated from field: string updatedState = 7;
   */
  updatedState = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 8;
   */
  body?: ContentUpdates;

  /**
   * @generated from field: protogen.UploadAttachment profileImage = 9;
   */
  profileImage?: UploadAttachment;

  /**
   * @generated from field: bool isProfilePublic = 10;
   */
  isProfilePublic = false;

  /**
   * @generated from field: bool shouldUpdateProfileImage = 11;
   */
  shouldUpdateProfileImage = false;

  /**
   *  string updatedUsername = 6;
   *  string updatedPassword = 7;
   *  string updatedAdvisorPhone = 7;
   *
   * @generated from field: repeated protogen.ExternalLinks externalLinks = 12;
   */
  externalLinks: ExternalLinks[] = [];

  constructor(data?: PartialMessage<UpdateAdvisorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateAdvisorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedFirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatedLastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updatedPersonalEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updatedPersonalPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updatedCity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "updatedState", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "body", kind: "message", T: ContentUpdates },
    { no: 9, name: "profileImage", kind: "message", T: UploadAttachment },
    { no: 10, name: "isProfilePublic", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "shouldUpdateProfileImage", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "externalLinks", kind: "message", T: ExternalLinks, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAdvisorRequest {
    return new UpdateAdvisorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAdvisorRequest {
    return new UpdateAdvisorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAdvisorRequest {
    return new UpdateAdvisorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAdvisorRequest | PlainMessage<UpdateAdvisorRequest> | undefined, b: UpdateAdvisorRequest | PlainMessage<UpdateAdvisorRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAdvisorRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateAdvisorResponse
 */
export class UpdateAdvisorResponse extends Message<UpdateAdvisorResponse> {
  /**
   * @generated from field: protogen.Advisor advisor = 1;
   */
  advisor?: Advisor;

  constructor(data?: PartialMessage<UpdateAdvisorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateAdvisorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisor", kind: "message", T: Advisor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAdvisorResponse {
    return new UpdateAdvisorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAdvisorResponse {
    return new UpdateAdvisorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAdvisorResponse {
    return new UpdateAdvisorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAdvisorResponse | PlainMessage<UpdateAdvisorResponse> | undefined, b: UpdateAdvisorResponse | PlainMessage<UpdateAdvisorResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAdvisorResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateActivitySubscriptionRequest
 */
export class UpdateActivitySubscriptionRequest extends Message<UpdateActivitySubscriptionRequest> {
  /**
   * @generated from field: string entityType = 1;
   */
  entityType = "";

  /**
   * @generated from field: string entityRef = 2;
   */
  entityRef = "";

  /**
   *  bool emailEnabled = 3;
   *
   * @generated from field: bool pushEnabled = 3;
   */
  pushEnabled = false;

  constructor(data?: PartialMessage<UpdateActivitySubscriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateActivitySubscriptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pushEnabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateActivitySubscriptionRequest {
    return new UpdateActivitySubscriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateActivitySubscriptionRequest {
    return new UpdateActivitySubscriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateActivitySubscriptionRequest {
    return new UpdateActivitySubscriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateActivitySubscriptionRequest | PlainMessage<UpdateActivitySubscriptionRequest> | undefined, b: UpdateActivitySubscriptionRequest | PlainMessage<UpdateActivitySubscriptionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateActivitySubscriptionRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateActivitySubscriptionResponse
 */
export class UpdateActivitySubscriptionResponse extends Message<UpdateActivitySubscriptionResponse> {
  /**
   * @generated from field: protogen.ActivitySubscription subscription = 1;
   */
  subscription?: ActivitySubscription;

  constructor(data?: PartialMessage<UpdateActivitySubscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateActivitySubscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subscription", kind: "message", T: ActivitySubscription },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateActivitySubscriptionResponse {
    return new UpdateActivitySubscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateActivitySubscriptionResponse {
    return new UpdateActivitySubscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateActivitySubscriptionResponse {
    return new UpdateActivitySubscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateActivitySubscriptionResponse | PlainMessage<UpdateActivitySubscriptionResponse> | undefined, b: UpdateActivitySubscriptionResponse | PlainMessage<UpdateActivitySubscriptionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateActivitySubscriptionResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorProfileRequest
 */
export class FetchAdvisorProfileRequest extends Message<FetchAdvisorProfileRequest> {
  /**
   * @generated from field: string advisorRef = 1;
   */
  advisorRef = "";

  constructor(data?: PartialMessage<FetchAdvisorProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorProfileRequest {
    return new FetchAdvisorProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorProfileRequest {
    return new FetchAdvisorProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorProfileRequest {
    return new FetchAdvisorProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorProfileRequest | PlainMessage<FetchAdvisorProfileRequest> | undefined, b: FetchAdvisorProfileRequest | PlainMessage<FetchAdvisorProfileRequest> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorProfileRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorProfileResponse
 */
export class FetchAdvisorProfileResponse extends Message<FetchAdvisorProfileResponse> {
  /**
   * @generated from field: protogen.Advisor advisor = 1;
   */
  advisor?: Advisor;

  constructor(data?: PartialMessage<FetchAdvisorProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisor", kind: "message", T: Advisor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorProfileResponse {
    return new FetchAdvisorProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorProfileResponse {
    return new FetchAdvisorProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorProfileResponse {
    return new FetchAdvisorProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorProfileResponse | PlainMessage<FetchAdvisorProfileResponse> | undefined, b: FetchAdvisorProfileResponse | PlainMessage<FetchAdvisorProfileResponse> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorProfileResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetConversationFeedRequest
 */
export class GetConversationFeedRequest extends Message<GetConversationFeedRequest> {
  /**
   * @generated from field: string conversationRef = 1;
   */
  conversationRef = "";

  /**
   * Need to enrich this.
   *
   * @generated from field: string startCursor = 2;
   */
  startCursor = "";

  /**
   * @generated from field: bool markConversationsRead = 3;
   */
  markConversationsRead = false;

  constructor(data?: PartialMessage<GetConversationFeedRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetConversationFeedRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "conversationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "startCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "markConversationsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConversationFeedRequest {
    return new GetConversationFeedRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConversationFeedRequest {
    return new GetConversationFeedRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConversationFeedRequest {
    return new GetConversationFeedRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetConversationFeedRequest | PlainMessage<GetConversationFeedRequest> | undefined, b: GetConversationFeedRequest | PlainMessage<GetConversationFeedRequest> | undefined): boolean {
    return proto3.util.equals(GetConversationFeedRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetConversationFeedResponse
 */
export class GetConversationFeedResponse extends Message<GetConversationFeedResponse> {
  /**
   * @generated from field: repeated protogen.FeedEntry entries = 1;
   */
  entries: FeedEntry[] = [];

  /**
   * @generated from field: repeated protogen.AccountStub accounts = 2;
   */
  accounts: AccountStub[] = [];

  /**
   * @generated from field: string startCursor = 3;
   */
  startCursor = "";

  /**
   * @generated from field: string nextCursor = 4;
   */
  nextCursor = "";

  /**
   * @generated from field: bool hasMore = 5;
   */
  hasMore = false;

  /**
   * @generated from field: protogen.AccountStub contact = 6;
   */
  contact?: AccountStub;

  constructor(data?: PartialMessage<GetConversationFeedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetConversationFeedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: FeedEntry, repeated: true },
    { no: 2, name: "accounts", kind: "message", T: AccountStub, repeated: true },
    { no: 3, name: "startCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "hasMore", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "contact", kind: "message", T: AccountStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConversationFeedResponse {
    return new GetConversationFeedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConversationFeedResponse {
    return new GetConversationFeedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConversationFeedResponse {
    return new GetConversationFeedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConversationFeedResponse | PlainMessage<GetConversationFeedResponse> | undefined, b: GetConversationFeedResponse | PlainMessage<GetConversationFeedResponse> | undefined): boolean {
    return proto3.util.equals(GetConversationFeedResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchPhoneInboxRequest
 */
export class FetchPhoneInboxRequest extends Message<FetchPhoneInboxRequest> {
  /**
   * @generated from field: string cursor = 1;
   */
  cursor = "";

  constructor(data?: PartialMessage<FetchPhoneInboxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchPhoneInboxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchPhoneInboxRequest {
    return new FetchPhoneInboxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchPhoneInboxRequest {
    return new FetchPhoneInboxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchPhoneInboxRequest {
    return new FetchPhoneInboxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchPhoneInboxRequest | PlainMessage<FetchPhoneInboxRequest> | undefined, b: FetchPhoneInboxRequest | PlainMessage<FetchPhoneInboxRequest> | undefined): boolean {
    return proto3.util.equals(FetchPhoneInboxRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchPhoneInboxResponse
 */
export class FetchPhoneInboxResponse extends Message<FetchPhoneInboxResponse> {
  /**
   * @generated from field: repeated protogen.FetchPhoneInboxResponse.InboxEntry entries = 1;
   */
  entries: FetchPhoneInboxResponse_InboxEntry[] = [];

  /**
   * @generated from field: string nextCursor = 2;
   */
  nextCursor = "";

  constructor(data?: PartialMessage<FetchPhoneInboxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchPhoneInboxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: FetchPhoneInboxResponse_InboxEntry, repeated: true },
    { no: 2, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchPhoneInboxResponse {
    return new FetchPhoneInboxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchPhoneInboxResponse {
    return new FetchPhoneInboxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchPhoneInboxResponse {
    return new FetchPhoneInboxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchPhoneInboxResponse | PlainMessage<FetchPhoneInboxResponse> | undefined, b: FetchPhoneInboxResponse | PlainMessage<FetchPhoneInboxResponse> | undefined): boolean {
    return proto3.util.equals(FetchPhoneInboxResponse, a, b);
  }
}

/**
 * @generated from enum protogen.FetchPhoneInboxResponse.InboxEntryType
 */
export enum FetchPhoneInboxResponse_InboxEntryType {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: TEXT = 1;
   */
  TEXT = 1,

  /**
   * @generated from enum value: CALL = 2;
   */
  CALL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(FetchPhoneInboxResponse_InboxEntryType)
proto3.util.setEnumType(FetchPhoneInboxResponse_InboxEntryType, "protogen.FetchPhoneInboxResponse.InboxEntryType", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "TEXT" },
  { no: 2, name: "CALL" },
]);

/**
 * @generated from enum protogen.FetchPhoneInboxResponse.InboxTreatment
 */
export enum FetchPhoneInboxResponse_InboxTreatment {
  /**
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * @generated from enum value: UNREAD = 1;
   */
  UNREAD = 1,

  /**
   * @generated from enum value: MISSED = 2;
   */
  MISSED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(FetchPhoneInboxResponse_InboxTreatment)
proto3.util.setEnumType(FetchPhoneInboxResponse_InboxTreatment, "protogen.FetchPhoneInboxResponse.InboxTreatment", [
  { no: 0, name: "NONE" },
  { no: 1, name: "UNREAD" },
  { no: 2, name: "MISSED" },
]);

/**
 * @generated from message protogen.FetchPhoneInboxResponse.InboxEntry
 */
export class FetchPhoneInboxResponse_InboxEntry extends Message<FetchPhoneInboxResponse_InboxEntry> {
  /**
   * @generated from field: protogen.FetchPhoneInboxResponse.InboxEntryType type = 1;
   */
  type = FetchPhoneInboxResponse_InboxEntryType.UNKNOWN;

  /**
   * @generated from field: string ref = 2;
   */
  ref = "";

  /**
   * @generated from field: protogen.AccountStub sender = 3;
   */
  sender?: AccountStub;

  /**
   * @generated from field: protogen.AccountStub recipient = 4;
   */
  recipient?: AccountStub;

  /**
   * @generated from field: int64 timestampSec = 6;
   */
  timestampSec = protoInt64.zero;

  /**
   * @generated from field: protogen.FetchPhoneInboxResponse.InboxTreatment treatment = 7;
   */
  treatment = FetchPhoneInboxResponse_InboxTreatment.NONE;

  /**
   * @generated from field: int32 unreadCount = 8;
   */
  unreadCount = 0;

  /**
   * @generated from field: string title = 9;
   */
  title = "";

  /**
   * Only present if the thread is associated with a family.
   *
   * @generated from field: protogen.FamilyStub family = 10;
   */
  family?: FamilyStub;

  constructor(data?: PartialMessage<FetchPhoneInboxResponse_InboxEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchPhoneInboxResponse.InboxEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(FetchPhoneInboxResponse_InboxEntryType) },
    { no: 2, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sender", kind: "message", T: AccountStub },
    { no: 4, name: "recipient", kind: "message", T: AccountStub },
    { no: 6, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "treatment", kind: "enum", T: proto3.getEnumType(FetchPhoneInboxResponse_InboxTreatment) },
    { no: 8, name: "unreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "family", kind: "message", T: FamilyStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchPhoneInboxResponse_InboxEntry {
    return new FetchPhoneInboxResponse_InboxEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchPhoneInboxResponse_InboxEntry {
    return new FetchPhoneInboxResponse_InboxEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchPhoneInboxResponse_InboxEntry {
    return new FetchPhoneInboxResponse_InboxEntry().fromJsonString(jsonString, options);
  }

  static equals(a: FetchPhoneInboxResponse_InboxEntry | PlainMessage<FetchPhoneInboxResponse_InboxEntry> | undefined, b: FetchPhoneInboxResponse_InboxEntry | PlainMessage<FetchPhoneInboxResponse_InboxEntry> | undefined): boolean {
    return proto3.util.equals(FetchPhoneInboxResponse_InboxEntry, a, b);
  }
}

/**
 * @generated from message protogen.StartExternalConversationRequest
 */
export class StartExternalConversationRequest extends Message<StartExternalConversationRequest> {
  /**
   * @generated from field: string phoneNumber = 1;
   */
  phoneNumber = "";

  constructor(data?: PartialMessage<StartExternalConversationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.StartExternalConversationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phoneNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartExternalConversationRequest {
    return new StartExternalConversationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartExternalConversationRequest {
    return new StartExternalConversationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartExternalConversationRequest {
    return new StartExternalConversationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartExternalConversationRequest | PlainMessage<StartExternalConversationRequest> | undefined, b: StartExternalConversationRequest | PlainMessage<StartExternalConversationRequest> | undefined): boolean {
    return proto3.util.equals(StartExternalConversationRequest, a, b);
  }
}

/**
 * @generated from message protogen.StartExternalConversationResponse
 */
export class StartExternalConversationResponse extends Message<StartExternalConversationResponse> {
  /**
   * @generated from field: string conversationRef = 1;
   */
  conversationRef = "";

  /**
   * @generated from field: protogen.AccountStub contact = 2;
   */
  contact?: AccountStub;

  constructor(data?: PartialMessage<StartExternalConversationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.StartExternalConversationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "conversationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contact", kind: "message", T: AccountStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartExternalConversationResponse {
    return new StartExternalConversationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartExternalConversationResponse {
    return new StartExternalConversationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartExternalConversationResponse {
    return new StartExternalConversationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartExternalConversationResponse | PlainMessage<StartExternalConversationResponse> | undefined, b: StartExternalConversationResponse | PlainMessage<StartExternalConversationResponse> | undefined): boolean {
    return proto3.util.equals(StartExternalConversationResponse, a, b);
  }
}

