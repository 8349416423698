import useProtoMethod from "./useProtoMethod";
import {
  CreateCommentRequest,
  CreateCommentResponse,
  CreatePostRequest,
  CreatePostResponse,
  EditCommentRequest,
  EditCommentResponse,
  EditPostRequest,
  EditPostResponse,
  EditCommentLikeRequest,
  EditCommentLikeResponse,
  FetchForumPageRequest,
  FetchForumPageResponse,
  FetchForumPostRequest,
  FetchForumPostResponse,
} from "../protogen/forum_service_pb";

const useCreatePost = (callback?: (r: CreatePostResponse) => void) => {
  return useProtoMethod<CreatePostRequest, CreatePostResponse>(
    "CreatePost",
    CreatePostResponse,
    { callback },
  );
};

const useEditPost = (callback?: (r: EditPostResponse) => void) => {
  return useProtoMethod<EditPostRequest, EditPostResponse>(
    "EditPost",
    EditPostResponse,
    { callback },
  );
};

const useCreateComment = (callback?: (r: CreateCommentResponse) => void) => {
  return useProtoMethod<CreateCommentRequest, CreateCommentResponse>(
    "CreateComment",
    CreateCommentResponse,
    { callback },
  );
};

const useEditComment = (callback?: (r: EditCommentResponse) => void) => {
  return useProtoMethod<EditCommentRequest, EditCommentResponse>(
    "EditComment",
    EditCommentResponse,
    { callback },
  );
};

const useEditCommentLike = (
  callback?: (r: EditCommentLikeResponse) => void,
) => {
  return useProtoMethod<EditCommentLikeRequest, EditCommentLikeResponse>(
    "EditCommentLike",
    EditCommentLikeResponse,
    { callback },
  );
};

const useFetchPost = (callback?: (r: FetchForumPostResponse) => void) => {
  return useProtoMethod<FetchForumPostRequest, FetchForumPostResponse>(
    "FetchForumPost",
    FetchForumPostResponse,
    { callback },
  );
};

const useFetchForumPage = (callback?: (r: FetchForumPageResponse) => void) => {
  return useProtoMethod<FetchForumPageRequest, FetchForumPageResponse>(
    "FetchForumPage",
    FetchForumPageResponse,
    { callback },
  );
};

export {
  useCreatePost,
  useCreateComment,
  useFetchPost,
  useFetchForumPage,
  useEditPost,
  useEditComment,
  useEditCommentLike,
};
