// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/tasks.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AccountStub, FamilyStub } from "./common_pb.ts";
import { Note } from "./notes_pb.ts";

/**
 * @generated from enum protogen.TaskState
 */
export enum TaskState {
  /**
   * @generated from enum value: TASK_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TASK_STATE_OPEN = 1;
   */
  OPEN = 1,

  /**
   * @generated from enum value: TASK_STATE_INPROGRESS = 2;
   */
  INPROGRESS = 2,

  /**
   * @generated from enum value: TASK_STATE_COMPLETED = 3;
   */
  COMPLETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskState)
proto3.util.setEnumType(TaskState, "protogen.TaskState", [
  { no: 0, name: "TASK_STATE_UNSPECIFIED" },
  { no: 1, name: "TASK_STATE_OPEN" },
  { no: 2, name: "TASK_STATE_INPROGRESS" },
  { no: 3, name: "TASK_STATE_COMPLETED" },
]);

/**
 * @generated from message protogen.TaskTimer
 */
export class TaskTimer extends Message<TaskTimer> {
  /**
   * @generated from field: int32 hoursReported = 1;
   */
  hoursReported = 0;

  /**
   * @generated from field: int32 minutesReported = 2;
   */
  minutesReported = 0;

  constructor(data?: PartialMessage<TaskTimer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TaskTimer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hoursReported", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "minutesReported", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskTimer {
    return new TaskTimer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskTimer {
    return new TaskTimer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskTimer {
    return new TaskTimer().fromJsonString(jsonString, options);
  }

  static equals(a: TaskTimer | PlainMessage<TaskTimer> | undefined, b: TaskTimer | PlainMessage<TaskTimer> | undefined): boolean {
    return proto3.util.equals(TaskTimer, a, b);
  }
}

/**
 * @generated from message protogen.Task
 */
export class Task extends Message<Task> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string familyRef = 2;
   */
  familyRef = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string category = 4;
   */
  category = "";

  /**
   * @generated from field: string priority = 5;
   */
  priority = "";

  /**
   * @generated from field: string dueDate = 6;
   */
  dueDate = "";

  /**
   * @generated from field: protogen.TaskState state = 7;
   */
  state = TaskState.UNSPECIFIED;

  /**
   * @generated from field: protogen.AccountStub assignee = 8;
   */
  assignee?: AccountStub;

  /**
   * @generated from field: protogen.Note details = 9;
   */
  details?: Note;

  /**
   * @generated from field: int64 createdSec = 10;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: int64 lastUpdatedSec = 11;
   */
  lastUpdatedSec = protoInt64.zero;

  /**
   * @generated from field: protogen.AccountStub createdBy = 12;
   */
  createdBy?: AccountStub;

  /**
   * @generated from field: protogen.FamilyStub family = 13;
   */
  family?: FamilyStub;

  /**
   * @generated from field: protogen.TaskTimer timing = 14;
   */
  timing?: TaskTimer;

  constructor(data?: PartialMessage<Task>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Task";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "dueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "state", kind: "enum", T: proto3.getEnumType(TaskState) },
    { no: 8, name: "assignee", kind: "message", T: AccountStub },
    { no: 9, name: "details", kind: "message", T: Note },
    { no: 10, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "lastUpdatedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "createdBy", kind: "message", T: AccountStub },
    { no: 13, name: "family", kind: "message", T: FamilyStub },
    { no: 14, name: "timing", kind: "message", T: TaskTimer },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Task {
    return new Task().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJsonString(jsonString, options);
  }

  static equals(a: Task | PlainMessage<Task> | undefined, b: Task | PlainMessage<Task> | undefined): boolean {
    return proto3.util.equals(Task, a, b);
  }
}

