import { Box, Chip, IconButton, LinearProgress } from "@mui/material";

import {
  JSONContent,
  HTMLContent,
  useEditor,
  EditorContent,
} from "@tiptap/react";
import { EditorView } from "@tiptap/pm/view";
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";

import useIsRefFocused from "../hooks/useIsRefFocused";
import { Attachment, UploadAttachment } from "../../protogen/common_pb";
import {
  blobToBase64,
  extractImageReferences,
  generateRandomName,
  getExtensions,
  PLACEHOLDER_PATH,
  renderInitialContent,
} from "./utils";
import DragNDrop from "../common/DragNDrop";
import { useUploader } from "../creation/FileUploader";
import { ReactComponent as AttachmentIcon } from "../../icons/AttachmentIcon.svg";
import { PlainMessage } from "@bufbuild/protobuf";
import { RichContent } from "./utils";
import { Transaction } from "@tiptap/pm/state";
import { Editor } from "@tiptap/core/dist/packages/core/src";
import DescriptionIcon from "@mui/icons-material/Description";
import { styled } from "@mui/system";

const _TextFieldEditor = styled(EditorContent, {
  shouldForwardProp: (prop) => prop !== "minHeight",
})(
  ({} // minHeight='150px'
  : {
    minHeight?: string;
  }) => ({
    outline: "none",
    div: {
      outline: "none",
    },
    "& .ProseMirror p": {
      margin: 0,
    },
    "& .ProseMirror image, & .ProseMirror img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    "& .ProseMirror": {
      outline: "none",
      // minHeight: minHeight,
    },
    "& .ProseMirror-focused": {
      outline: "none",
    },
    ".ProseMirror p.is-editor-empty:first-of-type::before": {
      color: "#adb5bd",
      content: "attr(data-placeholder)",
      float: "left",
      height: 0,
      pointerEvents: "none",
    },
  }),
);

type Props = {
  setContent: (content: RichContent) => void;
  initialContent?: string | JSONContent | HTMLContent;
  initialAttachments?: Attachment[];
  placeholder?: string;
  passiveEditor?: boolean;
  secondaryAction?: ReactNode;
  primaryAction?: ReactNode;
  disabled?: boolean;
  editorMinHeight?: string;
  // If present, attachments will be enabled.
  attachmentsEnabled?: boolean;
  setDragState?: (dragging: boolean) => void;
  onCommandEnter?: () => void;
};

export type Handle = {
  updateAttachments: (attachments: Attachment[]) => void;
};

export default forwardRef<Handle, Props>(
  (
    {
      setContent,
      initialContent,
      placeholder,
      primaryAction,
      secondaryAction,
      disabled,
      setDragState,
      onCommandEnter,
      initialAttachments = [],
      attachmentsEnabled = false,
    }: Props,
    ref,
  ) => {
    const notesRef = React.useRef<HTMLDivElement>(null);
    const { isFocused } = useIsRefFocused(notesRef);
    const {
      onUpload,
      fileUploads,
      fileOnlyUploads,
      uploadPercentage,
      removeUpload,
      getCompleteAttachments,
      withAttachments,
      uploadsInProgress,
    } = useUploader({
      initialAttachments: initialAttachments,
    });
    const addImagePlaceholder = async (referenceName: string, file: File) => {
      const base64 = await blobToBase64(file);
      const src = base64 || PLACEHOLDER_PATH;
      if (editor) {
        editor
          .chain()
          .focus()
          .setImage({ src: src, title: referenceName })
          .run();
      }
    };

    const handlePaste = (view: EditorView, event: ClipboardEvent) => {
      const files = Array.from(event.clipboardData?.files || []);
      if (files.length > 0) {
        const references: (string | null)[] = [];
        for (const file of files) {
          if (file.type.startsWith("image/")) {
            const name = generateRandomName("pasted-");
            addImagePlaceholder(name, file);
            references.push(name);
          } else {
            references.push(null);
          }
          onUpload(files, references, setAttachments);
        }
        return true;
      }
      return false;
    };

    const handleKeyDown = (view: EditorView, event: KeyboardEvent) => {
      // Enter to send: Except if "shift-enter" for newlines or if it's a touch device.
      if (event.key === "Enter" && event.metaKey) {
        onCommandEnter && onCommandEnter();
      }
    };

    const updateAttachments = (attachments: Attachment[]) => {
      withAttachments(attachments);
      if (!editor) return;
      const attachmentMap = attachments.reduce(
        (mapping, a) => {
          mapping[a.inlineReference] = a;
          return mapping;
        },
        {} as { [inlineReference: string]: Attachment },
      );
      editor.state.doc.descendants((a, startPos) => {
        if (
          a.type.name === "image" &&
          attachmentMap[a.attrs.title] &&
          (a.attrs.src === PLACEHOLDER_PATH ||
            // Don't replace if it's a data URL, would disrupt the user experience.
            // || a.attrs.src.startsWith('data:')
            a.attrs.src === "")
        ) {
          const n = attachmentMap[a.attrs.title];
          const newNode = editor.schema.nodes.image.create({
            title: n.inlineReference,
            src: n.url,
          });
          const transaction = editor.state.tr.replaceWith(
            startPos,
            startPos + a.nodeSize,
            newNode,
          );
          return editor.view.dispatch(transaction);
        }
      });
    };
    useImperativeHandle(ref, () => ({
      updateAttachments: updateAttachments,
    }));

    const onUpdate = (props: { editor: Editor; transaction: Transaction }) => {
      if (props.transaction.steps.length === 1) {
        // @ts-ignore
        const slice = props.transaction?.steps[0]?.slice;
        if (
          slice?.content?.content?.length === 1 &&
          slice.content.content[0].type.name === "image" &&
          slice.content.content[0].attrs.src.startsWith("https://s3")
        ) {
          // This is a placeholder image, don't update content
          return;
        }
      }
      const references = extractImageReferences(props.editor.getJSON());
      const attachments = getCompleteAttachments();
      const filteredAttachments = attachments.filter(
        (a) => !a.inlineReference || references.has(a.inlineReference),
      );
      setContent({
        html: props.editor.getHTML(),
        text: props.editor.getText(),
        json: JSON.stringify(props.editor.getJSON()),
        attachments: filteredAttachments,
      });
    };

    const editor = useEditor({
      editable: !disabled,
      extensions: getExtensions({ placeholder }),
      content: renderInitialContent(initialContent, initialAttachments),
      onUpdate: onUpdate,
      editorProps: {
        handlePaste: handlePaste,
      },
    });

    useEffect(() => {
      if (editor) {
        editor.setOptions({
          onUpdate: onUpdate,
          editorProps: {
            handlePaste: handlePaste,
            handleKeyDown: onCommandEnter ? handleKeyDown : undefined,
          },
        });
      }
    }, [editor, fileUploads, handlePaste, onUpdate]);

    // Use the useCallback here because draft can change while we are uploading so we need to make sure it isn't stale.
    // Upon further reflection, I'm not sure I need useCallback nor if the state I'm listening to is relevant. This
    // is a duct tape solution that seems to be fine.
    const setAttachments = useCallback(
      (attachments: PlainMessage<UploadAttachment>[]) => {
        if (editor) {
          setContent({
            html: editor.getHTML(),
            text: editor.getText(),
            json: JSON.stringify(editor.getJSON()),
            attachments: attachments,
          });
        }
      },
      [editor, fileUploads],
    );

    if (!editor) return null;
    // const showEditor = !passiveEditor || isFocused;
    return (
      <DragNDrop
        enabled={attachmentsEnabled}
        setHover={setDragState}
        onUpload={(files) => {
          const inlineReferences = [];
          for (let i = 0; i < files.length; i++) {
            if (files[i].type.startsWith("image/")) {
              // maybe configure this by product? notes/etc.
              const name = generateRandomName("file-");
              addImagePlaceholder(name, files[i]);
              inlineReferences.push(name);
            } else {
              inlineReferences.push(null);
            }
          }
          onUpload(files, inlineReferences, setAttachments);
        }}
      >
        <Box
          display="flex"
          sx={{
            justifyContent: "center",
            alignItems: "flex-end",
            gap: "12px",
          }}
        >
          <div style={{ position: "relative", flexGrow: 1 }}>
            <div
              style={{
                padding: "9px 30px 9px 14px",
                borderRadius: "8px",
                border: "1px solid var(--gray-300, #D0D5DD)",
                background: "var(--base-white, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              }}
            >
              <_TextFieldEditor editor={editor} />
              {/* FileUploads */}
              {fileOnlyUploads.length > 0 && (
                <Box
                  display="flex"
                  sx={{
                    marginTop: "8px",
                    flexDirection: "row",
                    gap: "6px",
                    flexWrap: "wrap",
                  }}
                >
                  {fileOnlyUploads.map((fileUpload, i) => (
                    <Chip
                      key={i}
                      label={fileUpload.filename}
                      size="small"
                      sx={{ maxWidth: "120px" }}
                      icon={<DescriptionIcon />}
                      onDelete={() => removeUpload(fileUpload.filename)}
                    />
                  ))}
                </Box>
              )}
            </div>
            <div
              style={{
                position: "absolute",
                right: "0px",
                bottom: "4px",
              }}
            >
              <label>
                <IconButton component="div" disabled={uploadsInProgress}>
                  <AttachmentIcon />
                </IconButton>
                {uploadPercentage > 0 && (
                  <LinearProgress
                    variant="determinate"
                    value={uploadPercentage}
                    sx={{
                      bottom: "4px",
                      width: "30px",
                      left: "2px",
                    }}
                  />
                )}
                <input
                  multiple
                  disabled={uploadsInProgress}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) =>
                    onUpload(e.target.files, undefined, setAttachments)
                  }
                />
              </label>
            </div>
          </div>
          {secondaryAction}
          {primaryAction}
        </Box>
      </DragNDrop>
    );
  },
);
