import React, { ReactNode, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ListItem from "@mui/material/ListItem";
import { Task, TaskState } from "../../protogen/tasks_pb";
import { useUpdateTask } from "../../services/tasks";
import { updates } from "../../types/tasks";

interface State {
  value: TaskState;
  label: string;
  icon: ReactNode;
}

export const TASK_STATES: State[] = [
  {
    value: TaskState.OPEN,
    label: "Open",
    icon: <PlayArrowIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: TaskState.INPROGRESS,
    label: "Active",
    icon: <HourglassTopIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: TaskState.COMPLETED,
    label: "Done",
    icon: <DoneIcon sx={{ height: 24, width: 24 }} />,
  },
];

// export const getStateIcon = (state: string) => {
//   const stateObj = TASK_STATES.find((s) => s.value === state);
//   return stateObj ? stateObj.icon : null;
// };

export const getStateLabel = (state: TaskState) => {
  const stateObj = TASK_STATES.find((s) => s.value === state);
  return stateObj ? stateObj.label : null;
};

interface Props {
  task: Task;
  onUpdate?: (t: Task) => void;
  error?: string | null;
  disabled?: boolean;
  noBorder?: boolean;
  redirectOnComplete?: boolean;
}

const NO_BORDER_INPUT_STYLE = {
  boxShadow: "none",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
};

export default ({
  task,
  onUpdate,
  error,
  disabled,
  noBorder = false,
  redirectOnComplete = false,
}: Props) => {
  const [selectedState, setSelectedState] = useState<TaskState>(task.state);
  const { loading, request } = useUpdateTask((r) => {
    const newlyCompleted =
      task.state !== TaskState.COMPLETED &&
      r.task.state === TaskState.COMPLETED;
    if (redirectOnComplete && newlyCompleted) {
      window.location.href = `/tasks/${r.task.ref}?trackTime=1`;
      return;
    }
    onUpdate && onUpdate(r.task);
  });

  const handleChange = (event: SelectChangeEvent) => {
    const selectedState: TaskState = +event.target.value;
    setSelectedState(selectedState);
    if (selectedState !== task.state) {
      request(
        updates(task, {
          state: selectedState,
        }),
      );
    }
  };

  return (
    <FormControl sx={{ minWidth: "115px" }}>
      {!noBorder && <InputLabel>Status</InputLabel>}
      <Select
        autoWidth
        label={!noBorder ? "Status" : undefined}
        disabled={disabled || loading}
        error={!!error}
        input={<OutlinedInput label={!noBorder ? "Status" : undefined} />}
        value={selectedState.toString()}
        onClick={(e) => e.stopPropagation()}
        onChange={handleChange}
        sx={noBorder ? NO_BORDER_INPUT_STYLE : {}}
      >
        {TASK_STATES.map((state) => (
          <MenuItem key={state.value} value={state.value}>
            <ListItem component="div" disablePadding>
              <ListItemIcon sx={{ minWidth: "initial" }}>
                {state.icon}
              </ListItemIcon>
              {state.label}
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
