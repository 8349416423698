import useProtoMethod from "./useProtoMethod";
import {
  AssignEmailToInboxRequest,
  AssignEmailToInboxResponse,
  CreateOrUpdateEmailDraftRequest,
  CreateOrUpdateEmailDraftResponse,
  DeleteEmailDraftRequest,
  DeleteEmailDraftResponse,
  FetchEmailInboxRequest,
  FetchEmailInboxResponse,
  FetchUnassignedEmailsRequest,
  FetchUnassignedEmailsResponse,
  GetEmailDraftRequest,
  GetEmailDraftResponse,
  GetEmailThreadRequest,
  GetEmailThreadResponse,
  SendEmailDraftRequest,
  SendEmailDraftResponse,
} from "../protogen/email_service_pb";

const useFetchUnassignedEmails = (
  callback?: (r: FetchUnassignedEmailsResponse) => void,
) => {
  return useProtoMethod<
    FetchUnassignedEmailsRequest,
    FetchUnassignedEmailsResponse
  >("FetchUnassignedEmails", FetchUnassignedEmailsResponse, { callback });
};

const useAssignEmailToInbox = (
  callback?: (r: AssignEmailToInboxResponse) => void,
) => {
  return useProtoMethod<AssignEmailToInboxRequest, AssignEmailToInboxResponse>(
    "AssignEmailToInbox",
    AssignEmailToInboxResponse,
    { callback },
  );
};

const useGetEmailDraft = (callback?: (r: GetEmailDraftResponse) => void) => {
  return useProtoMethod<GetEmailDraftRequest, GetEmailDraftResponse>(
    "GetEmailDraft",
    GetEmailDraftResponse,
    { callback },
  );
};

const useUpdateEmailDraft = (
  callback?: (r: CreateOrUpdateEmailDraftResponse) => void,
) => {
  return useProtoMethod<
    CreateOrUpdateEmailDraftRequest,
    CreateOrUpdateEmailDraftResponse
  >("CreateOrUpdateEmailDraft", CreateOrUpdateEmailDraftResponse, { callback });
};

const useDeleteEmailDraft = (
  callback?: (r: DeleteEmailDraftResponse) => void,
) => {
  return useProtoMethod<DeleteEmailDraftRequest, DeleteEmailDraftResponse>(
    "DeleteEmailDraft",
    DeleteEmailDraftResponse,
    { callback },
  );
};

const useSendEmailDraft = (callback?: (r: SendEmailDraftResponse) => void) => {
  return useProtoMethod<SendEmailDraftRequest, SendEmailDraftResponse>(
    "SendEmailDraft",
    SendEmailDraftResponse,
    { callback },
  );
};

const useGetEmailThread = (callback?: (r: GetEmailThreadResponse) => void) => {
  return useProtoMethod<GetEmailThreadRequest, GetEmailThreadResponse>(
    "GetEmailThread",
    GetEmailThreadResponse,
    { callback },
  );
};

const useFetchEmailInbox = (
  callback?: (r: FetchEmailInboxResponse) => void,
) => {
  return useProtoMethod<FetchEmailInboxRequest, FetchEmailInboxResponse>(
    "FetchEmailInbox",
    FetchEmailInboxResponse,
    { callback },
  );
};

export {
  useFetchUnassignedEmails,
  useAssignEmailToInbox,
  useGetEmailDraft,
  useUpdateEmailDraft,
  useDeleteEmailDraft,
  useSendEmailDraft,
  useGetEmailThread,
  useFetchEmailInbox,
};
