import { PlainMessage } from "@bufbuild/protobuf";
import { Box, Button, ListItem, Switch, Snackbar } from "@mui/material";
import { styled } from "@mui/system";
import { useContext, useEffect, useState, useRef } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth";
import { RichContent } from "../components/editor/utils";
import { useUploader } from "../components/creation/FileUploader";
import DocumentEditor from "../components/editor/DocumentEditor";
import GridPage from "../components/layout/GridPage";
import NotificationSettings from "../components/settings/NotificationSettings";
import { UploadAttachment } from "../protogen/common_pb";
import { Advisor } from "../protogen/common_pb";
import { useFetchAdvisorProfile, useUpdateAdvisor } from "../services/advisor";
import { CurrentUserContext } from "../components/context/RequireAuth";
import { LoadingContactCard } from "../components/common/ContactCardEntry";
import CurrentUserAvatar from "../components/common/CurrentUserAvatar";
import { AvatarBadgeType } from "../components/common/CurrentUserAvatar";
import AdvisorDetails from "../components/advisor/AdvisorDetails";
import EditExternalLinksDialog from "../components/advisor/EditExternalLinksDialog";

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100vh",
});

const AdvisorProfile = ({ advisorRef }: { advisorRef: string }) => {
  const isFirstRender = useRef(true);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<RichContent | null>(null);
  const [advisor, setAdvisor] = useState<Advisor | undefined>(undefined);
  const [socialMediaDialog, setSocialMediaDialog] = useState<boolean>(false);
  const [isProfilePublic, setIsProfilePublic] = useState<boolean | undefined>(
    undefined,
  );
  const [profileImage, setProfileImage] = useState<
    PlainMessage<UploadAttachment> | null | undefined
  >(null);
  const {
    request: fetchRequest,
    data: fetchData,
    loading: fetchLoading,
  } = useFetchAdvisorProfile((r) => {
    setAdvisor(r.advisor);
  });
  const { request: updateRequest, loading: updateLoading } = useUpdateAdvisor(
    (r) => {
      setAdvisor(r.advisor);
    },
  );

  const handleCopyLink = () => {
    setOpen(true);
    navigator.clipboard.writeText(advisor?.profilePath || "");
  };

  const { onUpload } = useUploader({
    maxUploadBytes: 4900000,
  });
  const handleSave = () => {
    updateRequest({
      advisorRef: advisorRef,
      updatedFirstName: advisor?.firstName || "",
      updatedLastName: advisor?.lastName || "",
      updatedPersonalEmail: advisor?.personalEmail || "",
      updatedPersonalPhone: advisor?.personalPhone || "",
      updatedCity: advisor?.city || "",
      updatedState: advisor?.state || "",
      body: {
        contentType: "json",
        payload: content?.json || advisor?.blurbContent || "",
        textContent: content?.text || advisor?.blurbText || "",
        attachments: [],
      },
      profileImage: profileImage || undefined,
      isProfilePublic: isProfilePublic ?? advisor?.isProfilePublic ?? false,
      shouldUpdateProfileImage: profileImage !== null,
      externalLinks: advisor?.externalLinks || [],
    });
  };

  useEffect(() => {
    fetchRequest({ advisorRef });
  }, [advisorRef]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleSave();
  }, [profileImage, isProfilePublic]);

  if (!fetchData || fetchLoading) return <LoadingContactCard />;
  return (
    <Box sx={{ maxWidth: "862px", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          gap: "50px",
          marginBottom: "35px",
        }}
      >
        <Box
          sx={{
            aspectRatio: "1/1",
            flexGrow: 1,
            maxWidth: "200px",
            width: "100%",
          }}
        >
          <CurrentUserAvatar
            user={advisor}
            size={200}
            autoscale={true}
            badgeType={AvatarBadgeType.FILE_UPLOAD}
            onUpload={(file) => {
              onUpload(file, undefined, (u) => {
                setProfileImage(u.pop());
              });
            }}
          />
        </Box>
        <AdvisorDetails advisor={advisor} />
      </Box>
      <Box
        sx={{
          fontFamily: "SourceSerifPro",
          fontWeight: "600",
          fontSize: "22px",
        }}
      >
        About me
      </Box>
      <DocumentEditor
        sx={{
          backgroundColor: "var(--basewhite, #F2F4F7)",
          padding: "24px",
          border: "2px solid #F1F1F1",
          borderRadius: "8px",
        }}
        initialContent={
          advisor?.blurbContent ? JSON.parse(advisor?.blurbContent) : ""
        }
        disabled={fetchLoading}
        placeholder="Tell families a little about yourself..."
        attachmentsEnabled={false}
        setContent={setContent}
        primaryAction={
          <Button onClick={handleSave} disabled={fetchLoading || updateLoading}>
            Save
          </Button>
        }
        secondaryAction={
          updateLoading && (
            <Box
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              Saving...
            </Box>
          )
        }
      />
      <Button
        sx={{ marginTop: "10px" }}
        variant="outlined"
        onClick={() => {
          setSocialMediaDialog(true);
        }}
      >
        Update Social Media Links
      </Button>
      <ListItem>
        <ListItemText
          id="public-profile-enabled"
          primary="Enable Public Profile"
        />
        <Button variant="outlined" onClick={handleCopyLink}>
          Copy profile link
        </Button>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message="Copied to clipboard"
        ></Snackbar>
        <Switch
          edge="end"
          disabled={fetchLoading || updateLoading}
          onChange={() => {
            setIsProfilePublic(!advisor?.isProfilePublic);
          }}
          checked={advisor?.isProfilePublic}
          inputProps={{
            "aria-labelledby": "public-profile-enabled",
          }}
        />
      </ListItem>
      <EditExternalLinksDialog
        open={socialMediaDialog}
        onClose={() => {
          setSocialMediaDialog(false);
        }}
        advisor={advisor!}
      />
    </Box>
  );
};

export default () => {
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext);

  const handleLogout = async () => {
    await AuthService.logout();
    navigate("/");
  };

  return (
    <GridPage sx={{ padding: "70px" }}>
      <CenteredBox>
        <AdvisorProfile advisorRef={currentUser.ref} />
        <Button
          sx={{ margin: "10px 0", width: "200px" }}
          variant="outlined"
          endIcon={<CachedIcon />}
          onClick={handleLogout}
        >
          Logout
        </Button>
        <a
          target="_blank"
          href={"https://findfaye.com/privacy-policy"}
          style={{
            textDecoration: "none",
          }}
        >
          <h4>Privacy Policy</h4>
        </a>
        <NotificationSettings />
      </CenteredBox>
    </GridPage>
  );
};
