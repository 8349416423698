import useDeviceManager, {
  DeviceManagerReturnType,
} from "../phone/useDeviceManager";
import * as React from "react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { PhoneState } from "../../types/phone";

type contextType = {
  device: DeviceManagerReturnType;
  acceptNextCall: boolean;
  setAcceptNextCall: () => void;
  activeCallId: string | null;
  setActiveCallId: (s: string | null) => void;
};
export const CallContext = createContext<contextType>({
  device: {
    phoneState: PhoneState.NOT_CONFIGURED,
    isMuted: false,
  },
  acceptNextCall: false,
  setAcceptNextCall: () => {},
  activeCallId: null,
  setActiveCallId: () => {},
});

export const AddCallContext = ({ children }: { children: ReactNode }) => {
  const device = useDeviceManager();
  const [acceptNextCall, setAcceptNextCall] = useState(false);
  const [activeCallId, setActiveCallId] = useState<string | null>(null);

  useEffect(() => {
    const { acceptCall, phoneState } = device;
    if (phoneState === PhoneState.RINGING && acceptNextCall) {
      acceptCall && acceptCall();
      setAcceptNextCall(false);
    }
  }, [device, acceptNextCall]);
  return (
    <CallContext.Provider
      value={{
        device: device,
        acceptNextCall,
        setAcceptNextCall: () => setAcceptNextCall(true),
        activeCallId,
        setActiveCallId,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};
