import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { ReactNode } from "react";
import { styled } from "@mui/system";
import useIsMobile from "../hooks/useIsMobile";

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  color: "var(--gray-600, #475467)",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "18px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "var(--gray-900, #101828)",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "18px",
}));

interface HeadCell {
  id: string;
  label: string;
  disablePadding?: boolean;
  floatRight?: boolean; // tells it to float right
  hideMobile?: boolean;
}

type RowCell = {
  content: string | number | null | ReactNode;
  overflowWidth?: number;
  singleLine?: boolean;
  alignRight?: boolean;
};

type Props = {
  headers: HeadCell[];
  rows: {
    cells: RowCell[];
    onClick?: () => void;
  }[];
  footerContent?: ReactNode;
  placeholderContent?: ReactNode | string;
  hideMobileOverflow?: boolean;
};

export default ({
  headers,
  rows,
  footerContent,
  placeholderContent,
  hideMobileOverflow,
}: Props) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "24px",
        alignSelf: "stretch",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer
            sx={{
              overflowX: hideMobileOverflow && isMobile ? "hidden" : "auto",
            }}
          >
            <Table
              // sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  {/*<TableCell padding="checkbox">*/}
                  {/*</TableCell>*/}
                  {headers.map((headCell) => (
                    <StyledHeaderCell
                      key={headCell.id}
                      align={headCell.floatRight ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sx={{
                        ...(isMobile &&
                          headCell.hideMobile && { display: "none" }),
                      }}
                    >
                      <TableSortLabel>{headCell.label}</TableSortLabel>
                    </StyledHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={row.onClick}
                      sx={{ cursor: row.onClick ? "pointer" : "default" }}
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      {/*<TableCell padding="checkbox">*/}
                      {/*</TableCell>*/}
                      {row.cells.map((cell, index2) => (
                        <StyledTableCell
                          component="th"
                          key={index2}
                          id={labelId}
                          scope="row"
                          align={cell.alignRight ? "right" : undefined}
                          sx={{
                            ...(isMobile &&
                              headers[index2].hideMobile && {
                                display: "none",
                              }),
                            ...(cell.overflowWidth && {
                              maxWidth: `${cell.overflowWidth}px`,
                              overflow: "hidden",
                            }),
                            ...(cell.singleLine && {
                              textWrap: "nowrap",
                              textOverflow: "ellipsis",
                            }),
                          }}
                        >
                          {cell.content}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {footerContent}
          {placeholderContent &&
            rows.length === 0 &&
            typeof placeholderContent === "string" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  height: "130px",
                  alignItems: "center",
                }}
              >
                <Box>{placeholderContent}</Box>
              </Box>
            )}
          {placeholderContent &&
            rows.length === 0 &&
            typeof placeholderContent !== "string" &&
            placeholderContent}
        </Paper>
      </Box>
    </Box>
  );
};
