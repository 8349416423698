import React, { ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LuggageIcon from "@mui/icons-material/Luggage";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import CelebrationIcon from "@mui/icons-material/Celebration";
import HandymanIcon from "@mui/icons-material/Handyman";
import ListItem from "@mui/material/ListItem";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CakeIcon from "@mui/icons-material/Cake";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import EventIcon from "@mui/icons-material/Event";

interface Category {
  value: string;
  label: string;
  icon: ReactNode;
}

export const CATEGORIES: Category[] = [
  {
    value: "groceries",
    label: "Groceries",
    icon: <ShoppingBagIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "travel",
    label: "Travel",
    icon: <LuggageIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "kids",
    label: "Kids",
    icon: <ChildCareIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "activities",
    label: "Activities",
    icon: <SportsSoccerIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "gifts",
    label: "Gifts",
    icon: <CelebrationIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "home",
    label: "Home",
    icon: <HandymanIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "errands",
    label: "Errands",
    icon: <DirectionsRunIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "parties",
    label: "Parties",
    icon: <CakeIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "appointments",
    label: "Appointments",
    icon: <EventIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "holidays",
    label: "Holidays",
    icon: <AcUnitIcon sx={{ height: 24, width: 24 }} />,
  },
  // Add more categories as needed
];

export const getCategoryIcon = (category: string) => {
  const categoryObj = CATEGORIES.find((cat) => cat.value === category);
  return categoryObj ? categoryObj.icon : null;
};

interface Props {
  selectedCategory: string | null;
  onChange: (category: string | null) => void;
  error?: string | null;
  disabled?: boolean;
}

export default ({ selectedCategory, onChange, error, disabled }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel>Category</InputLabel>
      <Select
        label="Category"
        disabled={disabled}
        error={!!error}
        input={<OutlinedInput label="Category" />}
        value={selectedCategory || ""}
        onChange={handleChange}
      >
        {CATEGORIES.map((cat) => (
          <MenuItem key={cat.value} value={cat.value}>
            <ListItem component="div" disablePadding>
              <ListItemIcon>{cat.icon}</ListItemIcon>
              {cat.label}
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
