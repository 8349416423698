import * as React from "react";
import { Route, Routes } from "react-router-dom";
import StatMeThat from "./pages/StatMeThat";

const NoMatch = () => {
  return (
    <div>
      <h2>Nothing to see here!</h2>
    </div>
  );
};

export default () => {
  return (
    <Routes>
      {/*<Route path={'/login'} element={<Login />} />*/}
      <Route path="/" element={<StatMeThat />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};
