// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/auth_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from enum protogen.AccountType
 */
export enum AccountType {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: ADVISOR = 1;
   */
  ADVISOR = 1,

  /**
   * @generated from enum value: MEMBER = 2;
   */
  MEMBER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AccountType)
proto3.util.setEnumType(AccountType, "protogen.AccountType", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "ADVISOR" },
  { no: 2, name: "MEMBER" },
]);

/**
 * @generated from message protogen.AuthToken
 */
export class AuthToken extends Message<AuthToken> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * @generated from field: int64 validUntil = 3;
   */
  validUntil = protoInt64.zero;

  constructor(data?: PartialMessage<AuthToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AuthToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "validUntil", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthToken {
    return new AuthToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthToken {
    return new AuthToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthToken {
    return new AuthToken().fromJsonString(jsonString, options);
  }

  static equals(a: AuthToken | PlainMessage<AuthToken> | undefined, b: AuthToken | PlainMessage<AuthToken> | undefined): boolean {
    return proto3.util.equals(AuthToken, a, b);
  }
}

/**
 * @generated from message protogen.CurrentUser
 */
export class CurrentUser extends Message<CurrentUser> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  /**
   * @generated from field: string displayName = 4;
   */
  displayName = "";

  /**
   * @generated from field: string avatarUrl = 5;
   */
  avatarUrl = "";

  /**
   * @generated from field: string email = 6;
   */
  email = "";

  /**
   * No need to leak PII out anywhere.
   *
   * @generated from field: string phoneTeaser = 7;
   */
  phoneTeaser = "";

  /**
   * @generated from field: string emailTeaser = 8;
   */
  emailTeaser = "";

  /**
   * @generated from field: repeated string roles = 9;
   */
  roles: string[] = [];

  /**
   * @generated from field: bool hasAcceptedTermsOfService = 10;
   */
  hasAcceptedTermsOfService = false;

  constructor(data?: PartialMessage<CurrentUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CurrentUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "displayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "avatarUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "phoneTeaser", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "emailTeaser", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "roles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "hasAcceptedTermsOfService", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CurrentUser {
    return new CurrentUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CurrentUser {
    return new CurrentUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CurrentUser {
    return new CurrentUser().fromJsonString(jsonString, options);
  }

  static equals(a: CurrentUser | PlainMessage<CurrentUser> | undefined, b: CurrentUser | PlainMessage<CurrentUser> | undefined): boolean {
    return proto3.util.equals(CurrentUser, a, b);
  }
}

/**
 * @generated from message protogen.LoginRequest
 */
export class LoginRequest extends Message<LoginRequest> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  /**
   * Pass along any active subscription so that we can avoid re-triggering the request flow.
   *
   * @generated from field: string subscription = 3;
   */
  subscription = "";

  constructor(data?: PartialMessage<LoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "subscription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginRequest {
    return new LoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginRequest {
    return new LoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginRequest {
    return new LoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LoginRequest | PlainMessage<LoginRequest> | undefined, b: LoginRequest | PlainMessage<LoginRequest> | undefined): boolean {
    return proto3.util.equals(LoginRequest, a, b);
  }
}

/**
 * @generated from message protogen.LoginResponse
 */
export class LoginResponse extends Message<LoginResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.AuthToken refreshToken = 2;
   */
  refreshToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 3;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<LoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "refreshToken", kind: "message", T: AuthToken },
    { no: 3, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginResponse {
    return new LoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginResponse {
    return new LoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginResponse {
    return new LoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LoginResponse | PlainMessage<LoginResponse> | undefined, b: LoginResponse | PlainMessage<LoginResponse> | undefined): boolean {
    return proto3.util.equals(LoginResponse, a, b);
  }
}

/**
 * @generated from message protogen.RefreshTokenRequest
 */
export class RefreshTokenRequest extends Message<RefreshTokenRequest> {
  /**
   * @generated from field: string refreshToken = 1;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<RefreshTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RefreshTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "refreshToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshTokenRequest {
    return new RefreshTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshTokenRequest {
    return new RefreshTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshTokenRequest {
    return new RefreshTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshTokenRequest | PlainMessage<RefreshTokenRequest> | undefined, b: RefreshTokenRequest | PlainMessage<RefreshTokenRequest> | undefined): boolean {
    return proto3.util.equals(RefreshTokenRequest, a, b);
  }
}

/**
 * @generated from message protogen.RefreshTokenResponse
 */
export class RefreshTokenResponse extends Message<RefreshTokenResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 2;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<RefreshTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RefreshTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshTokenResponse {
    return new RefreshTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshTokenResponse {
    return new RefreshTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshTokenResponse {
    return new RefreshTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshTokenResponse | PlainMessage<RefreshTokenResponse> | undefined, b: RefreshTokenResponse | PlainMessage<RefreshTokenResponse> | undefined): boolean {
    return proto3.util.equals(RefreshTokenResponse, a, b);
  }
}

/**
 * @generated from message protogen.ResetPasswordRequest
 */
export class ResetPasswordRequest extends Message<ResetPasswordRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string password = 3;
   */
  password = "";

  /**
   * Pass along any active subscription so that we can avoid re-triggering the request flow.
   *
   * @generated from field: string subscription = 4;
   */
  subscription = "";

  constructor(data?: PartialMessage<ResetPasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ResetPasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subscription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordRequest | PlainMessage<ResetPasswordRequest> | undefined, b: ResetPasswordRequest | PlainMessage<ResetPasswordRequest> | undefined): boolean {
    return proto3.util.equals(ResetPasswordRequest, a, b);
  }
}

/**
 * @generated from message protogen.ResetPasswordResponse
 */
export class ResetPasswordResponse extends Message<ResetPasswordResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.AuthToken refreshToken = 2;
   */
  refreshToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 3;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<ResetPasswordResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ResetPasswordResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "refreshToken", kind: "message", T: AuthToken },
    { no: 3, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordResponse {
    return new ResetPasswordResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordResponse {
    return new ResetPasswordResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordResponse {
    return new ResetPasswordResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordResponse | PlainMessage<ResetPasswordResponse> | undefined, b: ResetPasswordResponse | PlainMessage<ResetPasswordResponse> | undefined): boolean {
    return proto3.util.equals(ResetPasswordResponse, a, b);
  }
}

/**
 * @generated from message protogen.InitiatePasswordResetRequest
 */
export class InitiatePasswordResetRequest extends Message<InitiatePasswordResetRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: protogen.InitiatePasswordResetRequest.Flow flow = 2;
   */
  flow = InitiatePasswordResetRequest_Flow.UNSPECIFIED;

  constructor(data?: PartialMessage<InitiatePasswordResetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiatePasswordResetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "flow", kind: "enum", T: proto3.getEnumType(InitiatePasswordResetRequest_Flow) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiatePasswordResetRequest {
    return new InitiatePasswordResetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiatePasswordResetRequest {
    return new InitiatePasswordResetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiatePasswordResetRequest {
    return new InitiatePasswordResetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitiatePasswordResetRequest | PlainMessage<InitiatePasswordResetRequest> | undefined, b: InitiatePasswordResetRequest | PlainMessage<InitiatePasswordResetRequest> | undefined): boolean {
    return proto3.util.equals(InitiatePasswordResetRequest, a, b);
  }
}

/**
 * @generated from enum protogen.InitiatePasswordResetRequest.Flow
 */
export enum InitiatePasswordResetRequest_Flow {
  /**
   * @generated from enum value: FLOW_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FLOW_RESET_PASSWORD = 1;
   */
  RESET_PASSWORD = 1,

  /**
   * @generated from enum value: FLOW_SETUP_ACCOUNT = 2;
   */
  SETUP_ACCOUNT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(InitiatePasswordResetRequest_Flow)
proto3.util.setEnumType(InitiatePasswordResetRequest_Flow, "protogen.InitiatePasswordResetRequest.Flow", [
  { no: 0, name: "FLOW_UNSPECIFIED" },
  { no: 1, name: "FLOW_RESET_PASSWORD" },
  { no: 2, name: "FLOW_SETUP_ACCOUNT" },
]);

/**
 * @generated from message protogen.InitiatePasswordResetResponse
 */
export class InitiatePasswordResetResponse extends Message<InitiatePasswordResetResponse> {
  constructor(data?: PartialMessage<InitiatePasswordResetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiatePasswordResetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiatePasswordResetResponse {
    return new InitiatePasswordResetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiatePasswordResetResponse {
    return new InitiatePasswordResetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiatePasswordResetResponse {
    return new InitiatePasswordResetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitiatePasswordResetResponse | PlainMessage<InitiatePasswordResetResponse> | undefined, b: InitiatePasswordResetResponse | PlainMessage<InitiatePasswordResetResponse> | undefined): boolean {
    return proto3.util.equals(InitiatePasswordResetResponse, a, b);
  }
}

/**
 * @generated from message protogen.InitiateMemberLoginRequest
 */
export class InitiateMemberLoginRequest extends Message<InitiateMemberLoginRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string phone = 2;
   */
  phone = "";

  constructor(data?: PartialMessage<InitiateMemberLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiateMemberLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateMemberLoginRequest {
    return new InitiateMemberLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateMemberLoginRequest {
    return new InitiateMemberLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateMemberLoginRequest {
    return new InitiateMemberLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateMemberLoginRequest | PlainMessage<InitiateMemberLoginRequest> | undefined, b: InitiateMemberLoginRequest | PlainMessage<InitiateMemberLoginRequest> | undefined): boolean {
    return proto3.util.equals(InitiateMemberLoginRequest, a, b);
  }
}

/**
 * @generated from message protogen.InitiateMemberLoginResponse
 */
export class InitiateMemberLoginResponse extends Message<InitiateMemberLoginResponse> {
  constructor(data?: PartialMessage<InitiateMemberLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiateMemberLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateMemberLoginResponse {
    return new InitiateMemberLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateMemberLoginResponse {
    return new InitiateMemberLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateMemberLoginResponse {
    return new InitiateMemberLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateMemberLoginResponse | PlainMessage<InitiateMemberLoginResponse> | undefined, b: InitiateMemberLoginResponse | PlainMessage<InitiateMemberLoginResponse> | undefined): boolean {
    return proto3.util.equals(InitiateMemberLoginResponse, a, b);
  }
}

/**
 * @generated from message protogen.MemberLoginRequest
 */
export class MemberLoginRequest extends Message<MemberLoginRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * Pass along any active subscription so that we can avoid re-triggering the request flow.
   *
   * @generated from field: string subscription = 4;
   */
  subscription = "";

  constructor(data?: PartialMessage<MemberLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.MemberLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subscription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MemberLoginRequest {
    return new MemberLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MemberLoginRequest {
    return new MemberLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MemberLoginRequest {
    return new MemberLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MemberLoginRequest | PlainMessage<MemberLoginRequest> | undefined, b: MemberLoginRequest | PlainMessage<MemberLoginRequest> | undefined): boolean {
    return proto3.util.equals(MemberLoginRequest, a, b);
  }
}

/**
 * @generated from message protogen.MemberLoginResponse
 */
export class MemberLoginResponse extends Message<MemberLoginResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.AuthToken refreshToken = 2;
   */
  refreshToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 3;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<MemberLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.MemberLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "refreshToken", kind: "message", T: AuthToken },
    { no: 3, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MemberLoginResponse {
    return new MemberLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MemberLoginResponse {
    return new MemberLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MemberLoginResponse {
    return new MemberLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MemberLoginResponse | PlainMessage<MemberLoginResponse> | undefined, b: MemberLoginResponse | PlainMessage<MemberLoginResponse> | undefined): boolean {
    return proto3.util.equals(MemberLoginResponse, a, b);
  }
}

/**
 *  string accessToken = 1;
 *  string refreshToken = 2;
 *
 * @generated from message protogen.LogoutRequest
 */
export class LogoutRequest extends Message<LogoutRequest> {
  constructor(data?: PartialMessage<LogoutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LogoutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutRequest {
    return new LogoutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutRequest | PlainMessage<LogoutRequest> | undefined, b: LogoutRequest | PlainMessage<LogoutRequest> | undefined): boolean {
    return proto3.util.equals(LogoutRequest, a, b);
  }
}

/**
 * @generated from message protogen.LogoutResponse
 */
export class LogoutResponse extends Message<LogoutResponse> {
  constructor(data?: PartialMessage<LogoutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LogoutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutResponse {
    return new LogoutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutResponse | PlainMessage<LogoutResponse> | undefined, b: LogoutResponse | PlainMessage<LogoutResponse> | undefined): boolean {
    return proto3.util.equals(LogoutResponse, a, b);
  }
}

/**
 * @generated from message protogen.AcceptTermsOfServiceRequest
 */
export class AcceptTermsOfServiceRequest extends Message<AcceptTermsOfServiceRequest> {
  /**
   * @generated from field: protogen.AccountType accountType = 1;
   */
  accountType = AccountType.UNKNOWN;

  /**
   * @generated from field: int32 termsOfServiceVersion = 2;
   */
  termsOfServiceVersion = 0;

  constructor(data?: PartialMessage<AcceptTermsOfServiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AcceptTermsOfServiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accountType", kind: "enum", T: proto3.getEnumType(AccountType) },
    { no: 2, name: "termsOfServiceVersion", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptTermsOfServiceRequest {
    return new AcceptTermsOfServiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptTermsOfServiceRequest {
    return new AcceptTermsOfServiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptTermsOfServiceRequest {
    return new AcceptTermsOfServiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptTermsOfServiceRequest | PlainMessage<AcceptTermsOfServiceRequest> | undefined, b: AcceptTermsOfServiceRequest | PlainMessage<AcceptTermsOfServiceRequest> | undefined): boolean {
    return proto3.util.equals(AcceptTermsOfServiceRequest, a, b);
  }
}

/**
 * @generated from message protogen.AcceptTermsOfServiceResponse
 */
export class AcceptTermsOfServiceResponse extends Message<AcceptTermsOfServiceResponse> {
  constructor(data?: PartialMessage<AcceptTermsOfServiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AcceptTermsOfServiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptTermsOfServiceResponse {
    return new AcceptTermsOfServiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptTermsOfServiceResponse {
    return new AcceptTermsOfServiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptTermsOfServiceResponse {
    return new AcceptTermsOfServiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptTermsOfServiceResponse | PlainMessage<AcceptTermsOfServiceResponse> | undefined, b: AcceptTermsOfServiceResponse | PlainMessage<AcceptTermsOfServiceResponse> | undefined): boolean {
    return proto3.util.equals(AcceptTermsOfServiceResponse, a, b);
  }
}

