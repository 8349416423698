import { styled } from "@mui/system";
import { Box, Tab, TabProps, Tabs, TabsProps } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash";

const StyledTab = styled((props: TabProps) => <Tab {...props} />)(
  ({ theme }) => ({
    color: "var(--gray-500, #667085)",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    padding: "14px 4px",
    textTransform: "none",
    "&.MuiButtonBase-root": {
      padding: 0,
      minWidth: 0,
    },
    "&.MuiTabs-indicator": {
      backgroundColor: "#000",
    },
    "&.Mui-selected": {
      color: "#000",
    },
  }),
);
const StyledTabs = styled((props: TabsProps) => <Tabs {...props} />)(
  ({ theme }) => ({
    // gap: '20px',
    "& .MuiTabs-flexContainer": {
      gap: "16px",
    },
    ".MuiTabs-indicator": {
      backgroundColor: "#000",
    },
  }),
);

interface ScrollTabContainerProps {
  onTabIndexChange: (t: number) => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
  transitionTimeout?: number;
}
export interface ScrollContainerHandle {
  scrollToTabIndex: (ind: number) => void;
}
const ScrollTabContainer = forwardRef<
  ScrollContainerHandle,
  ScrollTabContainerProps
>(
  (
    {
      onTabIndexChange,
      style,
      children,
      transitionTimeout = 1000,
    }: ScrollTabContainerProps,
    ref,
  ) => {
    const tabPanelRef = React.useRef<HTMLDivElement>(null);
    const [inTransition, setInTransition] = useState<number | null>(null);
    useEffect(() => {
      if (tabPanelRef.current && tabPanelRef.current.lastChild) {
        const containerHeight =
          tabPanelRef.current.getBoundingClientRect().height;
        const lastChildHeight =
          // @ts-ignore
          tabPanelRef.current.lastChild.getBoundingClientRect().height;
        if (containerHeight - lastChildHeight > 0) {
          tabPanelRef.current.style.paddingBottom = `${
            containerHeight - lastChildHeight
          }px`;
        }
      }
    }, [tabPanelRef]);

    const _handleScroll = (e: any) => {
      if (
        inTransition &&
        new Date().getTime() - inTransition <= transitionTimeout
      ) {
        return;
      } else if (inTransition) {
        setInTransition(null);
      }
      if (tabPanelRef.current) {
        const containerRect = tabPanelRef.current.getBoundingClientRect();
        const visibleTabs = React.Children.map(children, (_, index) => {
          const child = tabPanelRef.current!.children[index] as HTMLElement;
          if (child) {
            const childRect = child.getBoundingClientRect();
            // Check if bottom of panel is still in view
            return (
              childRect.bottom >= containerRect.top &&
              childRect.bottom <= containerRect.bottom
            );
          }
          return false;
        });
        const firstIndex = (visibleTabs || []).findIndex((b) => b);
        if (firstIndex >= 0) {
          onTabIndexChange(firstIndex);
        }
      }
    };
    const handleScroll = debounce(_handleScroll, 200, {
      leading: true,
      trailing: true,
      maxWait: 200,
    });

    useEffect(() => {
      if (tabPanelRef.current) {
        tabPanelRef.current.addEventListener("scroll", handleScroll);
        return () => {
          tabPanelRef.current?.removeEventListener("scroll", handleScroll);
        };
      }
    }, [tabPanelRef, children, inTransition]);

    useImperativeHandle(
      ref,
      () => ({
        scrollToTabIndex: (toIndex: number) => {
          if (tabPanelRef.current) {
            // Scroll to selected child.
            const scrollElement = tabPanelRef.current.children[toIndex];
            if (scrollElement) {
              setInTransition(new Date().getTime());
              scrollElement.scrollIntoView({ behavior: "smooth" });
            }
          }
        },
      }),
      [tabPanelRef, children, inTransition],
    );

    return (
      <Box
        ref={tabPanelRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          overflowY: "scroll",
          flexGrow: 1,
          "&::-webkit-scrollbar": {
            display: "none",
          },
          ...style,
        }}
      >
        {children}
      </Box>
    );
  },
);

export { StyledTab, StyledTabs, ScrollTabContainer };
