// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/common.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message protogen.FamilyStub
 */
export class FamilyStub extends Message<FamilyStub> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string advisorRef = 3;
   */
  advisorRef = "";

  /**
   * @generated from field: string advisorName = 4;
   */
  advisorName = "";

  constructor(data?: PartialMessage<FamilyStub>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FamilyStub";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "advisorName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FamilyStub {
    return new FamilyStub().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FamilyStub {
    return new FamilyStub().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FamilyStub {
    return new FamilyStub().fromJsonString(jsonString, options);
  }

  static equals(a: FamilyStub | PlainMessage<FamilyStub> | undefined, b: FamilyStub | PlainMessage<FamilyStub> | undefined): boolean {
    return proto3.util.equals(FamilyStub, a, b);
  }
}

/**
 * @generated from message protogen.AccountStub
 */
export class AccountStub extends Message<AccountStub> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  /**
   * @generated from field: string displayName = 4;
   */
  displayName = "";

  /**
   * @generated from field: string avatarUrl = 5;
   */
  avatarUrl = "";

  /**
   * @generated from field: string profilePath = 6;
   */
  profilePath = "";

  /**
   * @generated from field: bool isAdvisor = 7;
   */
  isAdvisor = false;

  constructor(data?: PartialMessage<AccountStub>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AccountStub";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "displayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "avatarUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "profilePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "isAdvisor", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountStub {
    return new AccountStub().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountStub {
    return new AccountStub().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountStub {
    return new AccountStub().fromJsonString(jsonString, options);
  }

  static equals(a: AccountStub | PlainMessage<AccountStub> | undefined, b: AccountStub | PlainMessage<AccountStub> | undefined): boolean {
    return proto3.util.equals(AccountStub, a, b);
  }
}

/**
 * @generated from message protogen.Advisor
 */
export class Advisor extends Message<Advisor> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  /**
   * @generated from field: string displayName = 4;
   */
  displayName = "";

  /**
   * @generated from field: repeated string roles = 5;
   */
  roles: string[] = [];

  /**
   * @generated from field: repeated protogen.FamilyStub families = 6;
   */
  families: FamilyStub[] = [];

  /**
   * @generated from field: string username = 7;
   */
  username = "";

  /**
   * @generated from field: string personalEmail = 8;
   */
  personalEmail = "";

  /**
   * @generated from field: string personalPhone = 9;
   */
  personalPhone = "";

  /**
   * @generated from field: string city = 10;
   */
  city = "";

  /**
   * @generated from field: string state = 11;
   */
  state = "";

  /**
   * @generated from field: string advisorPhone = 12;
   */
  advisorPhone = "";

  /**
   * @generated from field: string blurbText = 13;
   */
  blurbText = "";

  /**
   * @generated from field: string blurbContent = 14;
   */
  blurbContent = "";

  /**
   * @generated from field: string blurbContentType = 15;
   */
  blurbContentType = "";

  /**
   * @generated from field: string avatarUrl = 16;
   */
  avatarUrl = "";

  /**
   * @generated from field: bool isProfilePublic = 17;
   */
  isProfilePublic = false;

  /**
   * @generated from field: repeated protogen.ExternalLinks externalLinks = 18;
   */
  externalLinks: ExternalLinks[] = [];

  /**
   * @generated from field: string profilePath = 19;
   */
  profilePath = "";

  constructor(data?: PartialMessage<Advisor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Advisor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "displayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "roles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "families", kind: "message", T: FamilyStub, repeated: true },
    { no: 7, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "personalEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "personalPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "advisorPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "blurbText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "blurbContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "blurbContentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "avatarUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "isProfilePublic", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "externalLinks", kind: "message", T: ExternalLinks, repeated: true },
    { no: 19, name: "profilePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Advisor {
    return new Advisor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Advisor {
    return new Advisor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Advisor {
    return new Advisor().fromJsonString(jsonString, options);
  }

  static equals(a: Advisor | PlainMessage<Advisor> | undefined, b: Advisor | PlainMessage<Advisor> | undefined): boolean {
    return proto3.util.equals(Advisor, a, b);
  }
}

/**
 * @generated from message protogen.PublicAdvisor
 */
export class PublicAdvisor extends Message<PublicAdvisor> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  /**
   * @generated from field: string displayName = 4;
   */
  displayName = "";

  /**
   * @generated from field: string username = 7;
   */
  username = "";

  /**
   * @generated from field: string city = 10;
   */
  city = "";

  /**
   * @generated from field: string state = 11;
   */
  state = "";

  /**
   * @generated from field: string advisorPhone = 12;
   */
  advisorPhone = "";

  /**
   * @generated from field: string blurbText = 13;
   */
  blurbText = "";

  /**
   * @generated from field: string blurbContent = 14;
   */
  blurbContent = "";

  /**
   * @generated from field: string blurbContentType = 15;
   */
  blurbContentType = "";

  /**
   * @generated from field: string avatarUrl = 16;
   */
  avatarUrl = "";

  /**
   * @generated from field: repeated protogen.ExternalLinks externalLinks = 17;
   */
  externalLinks: ExternalLinks[] = [];

  constructor(data?: PartialMessage<PublicAdvisor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.PublicAdvisor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "displayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "advisorPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "blurbText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "blurbContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "blurbContentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "avatarUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "externalLinks", kind: "message", T: ExternalLinks, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PublicAdvisor {
    return new PublicAdvisor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PublicAdvisor {
    return new PublicAdvisor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PublicAdvisor {
    return new PublicAdvisor().fromJsonString(jsonString, options);
  }

  static equals(a: PublicAdvisor | PlainMessage<PublicAdvisor> | undefined, b: PublicAdvisor | PlainMessage<PublicAdvisor> | undefined): boolean {
    return proto3.util.equals(PublicAdvisor, a, b);
  }
}

/**
 * @generated from message protogen.UploadAttachment
 */
export class UploadAttachment extends Message<UploadAttachment> {
  /**
   * @generated from field: string filename = 1;
   */
  filename = "";

  /**
   * @generated from field: string contentType = 2;
   */
  contentType = "";

  /**
   * @generated from field: int32 fileSize = 3;
   */
  fileSize = 0;

  /**
   * @generated from field: string s3Key = 4;
   */
  s3Key = "";

  /**
   * @generated from field: string validationKey = 5;
   */
  validationKey = "";

  /**
   * @generated from field: string inlineReference = 6;
   */
  inlineReference = "";

  constructor(data?: PartialMessage<UploadAttachment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UploadAttachment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fileSize", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "s3Key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "validationKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "inlineReference", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadAttachment {
    return new UploadAttachment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadAttachment {
    return new UploadAttachment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadAttachment {
    return new UploadAttachment().fromJsonString(jsonString, options);
  }

  static equals(a: UploadAttachment | PlainMessage<UploadAttachment> | undefined, b: UploadAttachment | PlainMessage<UploadAttachment> | undefined): boolean {
    return proto3.util.equals(UploadAttachment, a, b);
  }
}

/**
 * @generated from message protogen.Attachment
 */
export class Attachment extends Message<Attachment> {
  /**
   * @generated from field: string filename = 1;
   */
  filename = "";

  /**
   * @generated from field: string contentType = 2;
   */
  contentType = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: int32 fileSize = 4;
   */
  fileSize = 0;

  /**
   * @generated from field: string inlineReference = 5;
   */
  inlineReference = "";

  constructor(data?: PartialMessage<Attachment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Attachment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fileSize", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "inlineReference", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Attachment {
    return new Attachment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Attachment {
    return new Attachment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Attachment {
    return new Attachment().fromJsonString(jsonString, options);
  }

  static equals(a: Attachment | PlainMessage<Attachment> | undefined, b: Attachment | PlainMessage<Attachment> | undefined): boolean {
    return proto3.util.equals(Attachment, a, b);
  }
}

/**
 * @generated from message protogen.ExternalLinks
 */
export class ExternalLinks extends Message<ExternalLinks> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<ExternalLinks>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ExternalLinks";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExternalLinks {
    return new ExternalLinks().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExternalLinks {
    return new ExternalLinks().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExternalLinks {
    return new ExternalLinks().fromJsonString(jsonString, options);
  }

  static equals(a: ExternalLinks | PlainMessage<ExternalLinks> | undefined, b: ExternalLinks | PlainMessage<ExternalLinks> | undefined): boolean {
    return proto3.util.equals(ExternalLinks, a, b);
  }
}

