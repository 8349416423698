import React, { useState } from "react";
import DetailsTableSection from "../details-page/DetailsTableSection";
import {
  Box,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  Button,
} from "@mui/material";
import Section, { SectionProps } from "../details-page/Section";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/system";
import { ReactComponent as PlusIcon } from "../../icons/Plus.svg";
import { SecretStub } from "../../protogen/secrets_pb";
import AddSecretDialog from "../secrets/AddSecretDialog";
import { getTypeIcon } from "../secrets/SecretTypeSelect";
import { useDeleteSecret, useRevealSecret } from "../../services/secrets";
import SnackPack, { SnackbarMessage } from "../common/SnackPack";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../common/ConfirmationDialog";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "12px",
    border: "1px solid #dadde9",
  },
}));

const titleSection = (
  <>
    Secrets
    <HtmlTooltip
      title={
        <React.Fragment>
          <em>Securely stored details about a family.</em>
        </React.Fragment>
      }
    >
      <HelpOutlineIcon
        sx={{
          fontSize: "16px",
          marginLeft: "8px",
          cursor: "pointer",
        }}
      />
    </HtmlTooltip>
  </>
);

const SecretCell = ({
  secret,
  onAccessed,
}: {
  secret: SecretStub;
  onAccessed: () => void;
}) => {
  const [revealed, setRevealed] = useState(false);
  const { request, data, loading } = useRevealSecret((r) => {
    setRevealed(true);
    onAccessed();
  });
  const handleReveal = () => {
    if (!loading) {
      request({
        secretRef: secret.ref,
      });
    }
  };
  if (revealed) {
    return (
      <div
        style={{
          textAlign: "right",
          fontStyle: "italic",
          fontWeight: 500,
        }}
      >
        {data?.secretValue || "Secret not set"}
      </div>
    );
  }
  return (
    <HtmlTooltip title={<em>Click to fetch secret from storage.</em>}>
      <div
        onClick={handleReveal}
        style={{
          width: "100%",
          filter: "blur(5px)",
          cursor: "pointer",
          userSelect: "none",
          textAlign: "right",
        }}
      >
        ****************
      </div>
    </HtmlTooltip>
  );
};

interface Props extends SectionProps {
  secrets: SecretStub[];
  familyRef: string;
}
export default ({ secrets, familyRef }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [showAll, setShowAll] = useState(false);
  const confirmState = useConfirmationDialog();
  const { request: deleteRequest } = useDeleteSecret();
  const TEASER_COUNT = 4;
  const [localSecrets, setLocalSecrets] = useState<SecretStub[]>(secrets);
  const shown = showAll ? localSecrets : localSecrets.slice(0, TEASER_COUNT);
  return (
    <Section
      title={titleSection}
      actionIcon={
        <Button startIcon={<PlusIcon />} onClick={() => setDialogOpen(true)}>
          Add
        </Button>
      }
    >
      <DetailsTableSection
        rows={shown.map((secret) => ({
          cells: [
            {
              content: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  {getTypeIcon(secret.valueType)}
                  {secret.name}
                </div>
              ),
            },
            {
              content: (
                <SecretCell
                  secret={secret}
                  onAccessed={() => {
                    setSnackPack((prev) => [
                      ...prev,
                      {
                        message: `Access Recorded`,
                        key: new Date().getTime(),
                      },
                    ]);
                  }}
                />
              ),
            },
            {
              content: (
                <div>
                  {/*<STB size="small" onClick={(e) => {}}>*/}
                  {/*  Share*/}
                  {/*</STB>*/}
                  {/*&middot;*/}
                  <Button
                    variant="outlined"
                    size="small"
                    // Oh my! That's a lot of nested callbacks.
                    onClick={(e) => {
                      confirmState.openDialog(() => {
                        // Make request.
                        deleteRequest({ secretRef: secret.ref }).then(() => {
                          setLocalSecrets((prevState) =>
                            prevState.filter((s) => s.ref !== secret.ref),
                          );
                        });
                      });
                    }}
                  >
                    Delete
                  </Button>
                </div>
              ),
              alignRight: true,
            },
          ],
        }))}
        placeholderContent="No secrets have been added yet."
        headers={[
          { id: "name", label: "Name" },
          { id: "secret", label: "Secret", floatRight: true },
          { id: "actions", label: "Actions", floatRight: true },
        ]}
        footerContent={
          !showAll &&
          secrets.length > TEASER_COUNT && (
            <Box
              onClick={() => setShowAll(true)}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                height: "80px",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography sx={{ fontStyle: "italic" }}>
                Display {secrets.length - TEASER_COUNT} more secret
                {secrets.length - TEASER_COUNT !== 1 ? "s" : ""}
              </Typography>
            </Box>
          )
        }
      />
      <SnackPack
        snackPack={snackPack}
        alertSeverity="warning"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <AddSecretDialog
        open={dialogOpen}
        familyRef={familyRef}
        onClose={(s?: SecretStub) => {
          if (s) {
            setLocalSecrets((prevState) => [s, ...prevState]);
          }
          setDialogOpen(false);
        }}
      />
      <ConfirmationDialog {...confirmState.dialogProps} />
    </Section>
  );
};
