import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import AddServiceWorker from "./components/context/AddServiceWorker";

import "./styleguide.css";
import axios from "axios";
import { getApiDomain, getInferenceDomain } from "./constants";
import AdvisorApp from "./AdvisorApp";
import MemberApp from "./MemberApp";
import StatMeApp from "./StatMeApp";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

const _warmupApi = async (domain: string) => {
  axios.post(`${domain}/health?warmup=true`);
};

const startupAdvisor = async () => {
  // Poke the inference server since it can take some time to warmup.
  await _warmupApi(getInferenceDomain());
  root.render(
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
        <AddServiceWorker>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AdvisorApp />
          </LocalizationProvider>
        </AddServiceWorker>
      </BrowserRouter>
    </ThemeProvider>,
  );
};

const startupMember = async () => {
  root.render(
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MemberApp />
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProvider>,
  );
};

const startupStatMethat = async () => {
  // Poke the inference server since it can take some time to warmup.
  await _warmupApi(getApiDomain());
  root.render(
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
        <StatMeApp />
      </BrowserRouter>
    </ThemeProvider>,
  );
};

if (process.env.REACT_APP_BUILD_TARGET === "statmethat") {
  startupStatMethat();
} else if (process.env.REACT_APP_BUILD_TARGET === "member") {
  startupMember();
} else {
  startupAdvisor();
}
