import useProtoMethod from "./useProtoMethod";
import {
  CreateFamilySecretRequest,
  CreateFamilySecretResponse,
  DeleteSecretRequest,
  DeleteSecretResponse,
  RevealSecretRequest,
  RevealSecretResponse,
} from "../protogen/secrets_service_pb";

const useCreateFamilySecret = (
  callback?: (r: CreateFamilySecretResponse) => void,
) => {
  return useProtoMethod<CreateFamilySecretRequest, CreateFamilySecretResponse>(
    "CreateFamilySecret",
    CreateFamilySecretResponse,
    { callback },
  );
};

const useDeleteSecret = (callback?: (r: DeleteSecretResponse) => void) => {
  return useProtoMethod<DeleteSecretRequest, DeleteSecretResponse>(
    "DeleteSecret",
    DeleteSecretResponse,
    { callback },
  );
};

const useRevealSecret = (callback?: (r: RevealSecretResponse) => void) => {
  return useProtoMethod<RevealSecretRequest, RevealSecretResponse>(
    "RevealSecret",
    RevealSecretResponse,
    { callback },
  );
};

export { useCreateFamilySecret, useDeleteSecret, useRevealSecret };
