import React, { ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import ListItem from "@mui/material/ListItem";
import PasswordIcon from "@mui/icons-material/Password";
import CreditCardIcon from "@mui/icons-material/CreditCard";

interface SecretType {
  value: string;
  label: string;
  icon: ReactNode;
}

const SECRET_TYPES: SecretType[] = [
  {
    value: "secret",
    label: "Secret",
    icon: <KeyIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "credit_card",
    label: "Credit Card",
    icon: <CreditCardIcon sx={{ height: 24, width: 24 }} />,
  },
  {
    value: "login",
    label: "Login",
    icon: <PasswordIcon sx={{ height: 24, width: 24 }} />,
  },
  // Add more categories as needed
];

export const getTypeIcon = (category: string) => {
  const categoryObj = SECRET_TYPES.find((cat) => cat.value === category);
  return categoryObj ? categoryObj.icon : null;
};

interface Props {
  selectedCategory: string | null;
  onChange: (category: string) => void;
  error?: string | null;
  disabled?: boolean;
}

export default ({ selectedCategory, onChange, error, disabled }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <FormControl
      sx={{
        width: "150px",
        maxWidth: "25%",
      }}
    >
      <InputLabel>Category</InputLabel>
      <Select
        sx={{
          ".MuiSelect-select": {
            ...(selectedCategory
              ? {
                  paddingTop: "13px",
                  paddingBottom: "13px",
                }
              : null),
          },
        }}
        disabled={disabled}
        error={!!error}
        input={<OutlinedInput label="Category" />}
        value={selectedCategory || ""}
        renderValue={(value) => {
          const obj = SECRET_TYPES.find((cat) => cat.value === value);
          return obj ? obj.icon : null;
        }}
        onChange={handleChange}
      >
        {SECRET_TYPES.map((cat) => (
          <MenuItem key={cat.value} value={cat.value}>
            <ListItem component="div" disablePadding>
              <ListItemIcon>{cat.icon}</ListItemIcon>
              {cat.label}
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
