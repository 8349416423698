// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/forum_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ContentUpdates, NoteContent } from "./notes_pb.ts";
import { AccountStub } from "./common_pb.ts";

/**
 * @generated from message protogen.ForumPost
 */
export class ForumPost extends Message<ForumPost> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: protogen.NoteContent body = 3;
   */
  body?: NoteContent;

  /**
   * @generated from field: protogen.AccountStub author = 4;
   */
  author?: AccountStub;

  /**
   * @generated from field: int64 createdSec = 5;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: int64 lastActivitySec = 6;
   */
  lastActivitySec = protoInt64.zero;

  /**
   * @generated from field: int32 commentCount = 7;
   */
  commentCount = 0;

  /**
   * @generated from field: int64 lastEditSec = 8;
   */
  lastEditSec = protoInt64.zero;

  constructor(data?: PartialMessage<ForumPost>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ForumPost";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "body", kind: "message", T: NoteContent },
    { no: 4, name: "author", kind: "message", T: AccountStub },
    { no: 5, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "lastActivitySec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "commentCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "lastEditSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ForumPost {
    return new ForumPost().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ForumPost {
    return new ForumPost().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ForumPost {
    return new ForumPost().fromJsonString(jsonString, options);
  }

  static equals(a: ForumPost | PlainMessage<ForumPost> | undefined, b: ForumPost | PlainMessage<ForumPost> | undefined): boolean {
    return proto3.util.equals(ForumPost, a, b);
  }
}

/**
 * @generated from message protogen.ForumComment
 */
export class ForumComment extends Message<ForumComment> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string postRef = 2;
   */
  postRef = "";

  /**
   * @generated from field: string parentRef = 3;
   */
  parentRef = "";

  /**
   * @generated from field: int32 depth = 4;
   */
  depth = 0;

  /**
   * @generated from field: protogen.NoteContent body = 5;
   */
  body?: NoteContent;

  /**
   * @generated from field: protogen.AccountStub author = 6;
   */
  author?: AccountStub;

  /**
   * @generated from field: int64 createdSec = 7;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: int64 lastEditSec = 8;
   */
  lastEditSec = protoInt64.zero;

  /**
   * @generated from field: bool isLikedByUser = 9;
   */
  isLikedByUser = false;

  /**
   * @generated from field: int32 totalLikes = 10;
   */
  totalLikes = 0;

  constructor(data?: PartialMessage<ForumComment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ForumComment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "parentRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "depth", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "body", kind: "message", T: NoteContent },
    { no: 6, name: "author", kind: "message", T: AccountStub },
    { no: 7, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "lastEditSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "isLikedByUser", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "totalLikes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ForumComment {
    return new ForumComment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ForumComment {
    return new ForumComment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ForumComment {
    return new ForumComment().fromJsonString(jsonString, options);
  }

  static equals(a: ForumComment | PlainMessage<ForumComment> | undefined, b: ForumComment | PlainMessage<ForumComment> | undefined): boolean {
    return proto3.util.equals(ForumComment, a, b);
  }
}

/**
 * @generated from message protogen.CreatePostRequest
 */
export class CreatePostRequest extends Message<CreatePostRequest> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 2;
   */
  body?: ContentUpdates;

  constructor(data?: PartialMessage<CreatePostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreatePostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "body", kind: "message", T: ContentUpdates },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePostRequest {
    return new CreatePostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePostRequest {
    return new CreatePostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePostRequest {
    return new CreatePostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePostRequest | PlainMessage<CreatePostRequest> | undefined, b: CreatePostRequest | PlainMessage<CreatePostRequest> | undefined): boolean {
    return proto3.util.equals(CreatePostRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreatePostResponse
 */
export class CreatePostResponse extends Message<CreatePostResponse> {
  /**
   * @generated from field: protogen.ForumPost post = 1;
   */
  post?: ForumPost;

  constructor(data?: PartialMessage<CreatePostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreatePostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post", kind: "message", T: ForumPost },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePostResponse {
    return new CreatePostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePostResponse {
    return new CreatePostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePostResponse {
    return new CreatePostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePostResponse | PlainMessage<CreatePostResponse> | undefined, b: CreatePostResponse | PlainMessage<CreatePostResponse> | undefined): boolean {
    return proto3.util.equals(CreatePostResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditPostRequest
 */
export class EditPostRequest extends Message<EditPostRequest> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 3;
   */
  body?: ContentUpdates;

  constructor(data?: PartialMessage<EditPostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditPostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "body", kind: "message", T: ContentUpdates },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditPostRequest {
    return new EditPostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditPostRequest {
    return new EditPostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditPostRequest {
    return new EditPostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditPostRequest | PlainMessage<EditPostRequest> | undefined, b: EditPostRequest | PlainMessage<EditPostRequest> | undefined): boolean {
    return proto3.util.equals(EditPostRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditPostResponse
 */
export class EditPostResponse extends Message<EditPostResponse> {
  /**
   * @generated from field: protogen.ForumPost post = 1;
   */
  post?: ForumPost;

  constructor(data?: PartialMessage<EditPostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditPostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post", kind: "message", T: ForumPost },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditPostResponse {
    return new EditPostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditPostResponse {
    return new EditPostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditPostResponse {
    return new EditPostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditPostResponse | PlainMessage<EditPostResponse> | undefined, b: EditPostResponse | PlainMessage<EditPostResponse> | undefined): boolean {
    return proto3.util.equals(EditPostResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateCommentRequest
 */
export class CreateCommentRequest extends Message<CreateCommentRequest> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: string parentRef = 2;
   */
  parentRef = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 3;
   */
  body?: ContentUpdates;

  constructor(data?: PartialMessage<CreateCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parentRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "body", kind: "message", T: ContentUpdates },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCommentRequest {
    return new CreateCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCommentRequest {
    return new CreateCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCommentRequest {
    return new CreateCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCommentRequest | PlainMessage<CreateCommentRequest> | undefined, b: CreateCommentRequest | PlainMessage<CreateCommentRequest> | undefined): boolean {
    return proto3.util.equals(CreateCommentRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateCommentResponse
 */
export class CreateCommentResponse extends Message<CreateCommentResponse> {
  /**
   * @generated from field: protogen.ForumComment comment = 1;
   */
  comment?: ForumComment;

  constructor(data?: PartialMessage<CreateCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "comment", kind: "message", T: ForumComment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCommentResponse {
    return new CreateCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCommentResponse {
    return new CreateCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCommentResponse {
    return new CreateCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCommentResponse | PlainMessage<CreateCommentResponse> | undefined, b: CreateCommentResponse | PlainMessage<CreateCommentResponse> | undefined): boolean {
    return proto3.util.equals(CreateCommentResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditCommentRequest
 */
export class EditCommentRequest extends Message<EditCommentRequest> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 2;
   */
  body?: ContentUpdates;

  constructor(data?: PartialMessage<EditCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "body", kind: "message", T: ContentUpdates },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommentRequest {
    return new EditCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommentRequest {
    return new EditCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommentRequest {
    return new EditCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommentRequest | PlainMessage<EditCommentRequest> | undefined, b: EditCommentRequest | PlainMessage<EditCommentRequest> | undefined): boolean {
    return proto3.util.equals(EditCommentRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditCommentResponse
 */
export class EditCommentResponse extends Message<EditCommentResponse> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: protogen.ForumComment comment = 2;
   */
  comment?: ForumComment;

  constructor(data?: PartialMessage<EditCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "comment", kind: "message", T: ForumComment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommentResponse {
    return new EditCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommentResponse {
    return new EditCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommentResponse {
    return new EditCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommentResponse | PlainMessage<EditCommentResponse> | undefined, b: EditCommentResponse | PlainMessage<EditCommentResponse> | undefined): boolean {
    return proto3.util.equals(EditCommentResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditCommentLikeRequest
 */
export class EditCommentLikeRequest extends Message<EditCommentLikeRequest> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string postRef = 2;
   */
  postRef = "";

  /**
   * @generated from field: bool isLiked = 3;
   */
  isLiked = false;

  constructor(data?: PartialMessage<EditCommentLikeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditCommentLikeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "isLiked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommentLikeRequest {
    return new EditCommentLikeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommentLikeRequest {
    return new EditCommentLikeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommentLikeRequest {
    return new EditCommentLikeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommentLikeRequest | PlainMessage<EditCommentLikeRequest> | undefined, b: EditCommentLikeRequest | PlainMessage<EditCommentLikeRequest> | undefined): boolean {
    return proto3.util.equals(EditCommentLikeRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditCommentLikeResponse
 */
export class EditCommentLikeResponse extends Message<EditCommentLikeResponse> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: bool isLiked = 2;
   */
  isLiked = false;

  constructor(data?: PartialMessage<EditCommentLikeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditCommentLikeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "isLiked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommentLikeResponse {
    return new EditCommentLikeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommentLikeResponse {
    return new EditCommentLikeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommentLikeResponse {
    return new EditCommentLikeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommentLikeResponse | PlainMessage<EditCommentLikeResponse> | undefined, b: EditCommentLikeResponse | PlainMessage<EditCommentLikeResponse> | undefined): boolean {
    return proto3.util.equals(EditCommentLikeResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchForumPostRequest
 */
export class FetchForumPostRequest extends Message<FetchForumPostRequest> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: bool markAsRead = 2;
   */
  markAsRead = false;

  constructor(data?: PartialMessage<FetchForumPostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchForumPostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "markAsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchForumPostRequest {
    return new FetchForumPostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchForumPostRequest {
    return new FetchForumPostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchForumPostRequest {
    return new FetchForumPostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchForumPostRequest | PlainMessage<FetchForumPostRequest> | undefined, b: FetchForumPostRequest | PlainMessage<FetchForumPostRequest> | undefined): boolean {
    return proto3.util.equals(FetchForumPostRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchForumPostResponse
 */
export class FetchForumPostResponse extends Message<FetchForumPostResponse> {
  /**
   * @generated from field: protogen.ForumPost post = 1;
   */
  post?: ForumPost;

  /**
   * @generated from field: repeated protogen.ForumComment comments = 2;
   */
  comments: ForumComment[] = [];

  constructor(data?: PartialMessage<FetchForumPostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchForumPostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post", kind: "message", T: ForumPost },
    { no: 2, name: "comments", kind: "message", T: ForumComment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchForumPostResponse {
    return new FetchForumPostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchForumPostResponse {
    return new FetchForumPostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchForumPostResponse {
    return new FetchForumPostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchForumPostResponse | PlainMessage<FetchForumPostResponse> | undefined, b: FetchForumPostResponse | PlainMessage<FetchForumPostResponse> | undefined): boolean {
    return proto3.util.equals(FetchForumPostResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchForumPageRequest
 */
export class FetchForumPageRequest extends Message<FetchForumPageRequest> {
  constructor(data?: PartialMessage<FetchForumPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchForumPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchForumPageRequest {
    return new FetchForumPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchForumPageRequest {
    return new FetchForumPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchForumPageRequest {
    return new FetchForumPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchForumPageRequest | PlainMessage<FetchForumPageRequest> | undefined, b: FetchForumPageRequest | PlainMessage<FetchForumPageRequest> | undefined): boolean {
    return proto3.util.equals(FetchForumPageRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchForumPageResponse
 */
export class FetchForumPageResponse extends Message<FetchForumPageResponse> {
  /**
   * @generated from field: repeated protogen.ForumPost posts = 1;
   */
  posts: ForumPost[] = [];

  constructor(data?: PartialMessage<FetchForumPageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchForumPageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "posts", kind: "message", T: ForumPost, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchForumPageResponse {
    return new FetchForumPageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchForumPageResponse {
    return new FetchForumPageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchForumPageResponse {
    return new FetchForumPageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchForumPageResponse | PlainMessage<FetchForumPageResponse> | undefined, b: FetchForumPageResponse | PlainMessage<FetchForumPageResponse> | undefined): boolean {
    return proto3.util.equals(FetchForumPageResponse, a, b);
  }
}

