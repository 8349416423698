// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/tasks_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ContentUpdates } from "./notes_pb.ts";
import { Task, TaskState, TaskTimer } from "./tasks_pb.ts";
import { FamilyStub } from "./common_pb.ts";

/**
 * @generated from message protogen.CreateTaskRequest
 */
export class CreateTaskRequest extends Message<CreateTaskRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string category = 3;
   */
  category = "";

  /**
   * @generated from field: string priority = 4;
   */
  priority = "";

  /**
   * @generated from field: string dueDate = 5;
   */
  dueDate = "";

  /**
   * @generated from field: string assigneeRef = 6;
   */
  assigneeRef = "";

  /**
   * @generated from field: protogen.ContentUpdates details = 7;
   */
  details?: ContentUpdates;

  /**
   * @generated from field: string sourceEntityType = 8;
   */
  sourceEntityType = "";

  /**
   * @generated from field: string sourceEntityRef = 9;
   */
  sourceEntityRef = "";

  constructor(data?: PartialMessage<CreateTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "dueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "assigneeRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "details", kind: "message", T: ContentUpdates },
    { no: 8, name: "sourceEntityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "sourceEntityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskRequest {
    return new CreateTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskRequest {
    return new CreateTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskRequest {
    return new CreateTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskRequest | PlainMessage<CreateTaskRequest> | undefined, b: CreateTaskRequest | PlainMessage<CreateTaskRequest> | undefined): boolean {
    return proto3.util.equals(CreateTaskRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateTaskResponse
 */
export class CreateTaskResponse extends Message<CreateTaskResponse> {
  /**
   * @generated from field: protogen.Task task = 1;
   */
  task?: Task;

  constructor(data?: PartialMessage<CreateTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task", kind: "message", T: Task },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTaskResponse {
    return new CreateTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTaskResponse {
    return new CreateTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTaskResponse {
    return new CreateTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTaskResponse | PlainMessage<CreateTaskResponse> | undefined, b: CreateTaskResponse | PlainMessage<CreateTaskResponse> | undefined): boolean {
    return proto3.util.equals(CreateTaskResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetTaskRequest
 */
export class GetTaskRequest extends Message<GetTaskRequest> {
  /**
   * @generated from field: string taskRef = 1;
   */
  taskRef = "";

  constructor(data?: PartialMessage<GetTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskRequest {
    return new GetTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskRequest {
    return new GetTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskRequest {
    return new GetTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskRequest | PlainMessage<GetTaskRequest> | undefined, b: GetTaskRequest | PlainMessage<GetTaskRequest> | undefined): boolean {
    return proto3.util.equals(GetTaskRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetTaskResponse
 */
export class GetTaskResponse extends Message<GetTaskResponse> {
  /**
   * @generated from field: protogen.Task task = 1;
   */
  task?: Task;

  constructor(data?: PartialMessage<GetTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task", kind: "message", T: Task },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTaskResponse {
    return new GetTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTaskResponse {
    return new GetTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTaskResponse {
    return new GetTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTaskResponse | PlainMessage<GetTaskResponse> | undefined, b: GetTaskResponse | PlainMessage<GetTaskResponse> | undefined): boolean {
    return proto3.util.equals(GetTaskResponse, a, b);
  }
}

/**
 * @generated from message protogen.EditTaskRequest
 */
export class EditTaskRequest extends Message<EditTaskRequest> {
  /**
   * @generated from field: string taskRef = 1;
   */
  taskRef = "";

  /**
   * @generated from field: string updatedTitle = 2;
   */
  updatedTitle = "";

  /**
   * @generated from field: string updatedCategory = 3;
   */
  updatedCategory = "";

  /**
   * @generated from field: string updatedPriority = 4;
   */
  updatedPriority = "";

  /**
   * @generated from field: string updatedDueDate = 5;
   */
  updatedDueDate = "";

  /**
   * @generated from field: string updatedAssigneeRef = 6;
   */
  updatedAssigneeRef = "";

  /**
   * @generated from field: protogen.TaskState updatedState = 7;
   */
  updatedState = TaskState.UNSPECIFIED;

  /**
   * @generated from field: protogen.ContentUpdates updatedDetails = 8;
   */
  updatedDetails?: ContentUpdates;

  /**
   * @generated from field: protogen.TaskTimer updatedTiming = 9;
   */
  updatedTiming?: TaskTimer;

  constructor(data?: PartialMessage<EditTaskRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditTaskRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedTitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatedCategory", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updatedPriority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updatedDueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updatedAssigneeRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "updatedState", kind: "enum", T: proto3.getEnumType(TaskState) },
    { no: 8, name: "updatedDetails", kind: "message", T: ContentUpdates },
    { no: 9, name: "updatedTiming", kind: "message", T: TaskTimer },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditTaskRequest {
    return new EditTaskRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditTaskRequest {
    return new EditTaskRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditTaskRequest {
    return new EditTaskRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditTaskRequest | PlainMessage<EditTaskRequest> | undefined, b: EditTaskRequest | PlainMessage<EditTaskRequest> | undefined): boolean {
    return proto3.util.equals(EditTaskRequest, a, b);
  }
}

/**
 * @generated from message protogen.EditTaskResponse
 */
export class EditTaskResponse extends Message<EditTaskResponse> {
  /**
   * @generated from field: protogen.Task task = 1;
   */
  task?: Task;

  constructor(data?: PartialMessage<EditTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EditTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task", kind: "message", T: Task },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditTaskResponse {
    return new EditTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditTaskResponse {
    return new EditTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditTaskResponse {
    return new EditTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditTaskResponse | PlainMessage<EditTaskResponse> | undefined, b: EditTaskResponse | PlainMessage<EditTaskResponse> | undefined): boolean {
    return proto3.util.equals(EditTaskResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForFamilyRequest
 */
export class ListTasksForFamilyRequest extends Message<ListTasksForFamilyRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  constructor(data?: PartialMessage<ListTasksForFamilyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForFamilyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForFamilyRequest {
    return new ListTasksForFamilyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForFamilyRequest {
    return new ListTasksForFamilyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForFamilyRequest {
    return new ListTasksForFamilyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForFamilyRequest | PlainMessage<ListTasksForFamilyRequest> | undefined, b: ListTasksForFamilyRequest | PlainMessage<ListTasksForFamilyRequest> | undefined): boolean {
    return proto3.util.equals(ListTasksForFamilyRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForFamilyResponse
 */
export class ListTasksForFamilyResponse extends Message<ListTasksForFamilyResponse> {
  /**
   * @generated from field: repeated protogen.Task tasks = 1;
   */
  tasks: Task[] = [];

  constructor(data?: PartialMessage<ListTasksForFamilyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForFamilyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tasks", kind: "message", T: Task, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForFamilyResponse {
    return new ListTasksForFamilyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForFamilyResponse {
    return new ListTasksForFamilyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForFamilyResponse {
    return new ListTasksForFamilyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForFamilyResponse | PlainMessage<ListTasksForFamilyResponse> | undefined, b: ListTasksForFamilyResponse | PlainMessage<ListTasksForFamilyResponse> | undefined): boolean {
    return proto3.util.equals(ListTasksForFamilyResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForAdvisorRequest
 */
export class ListTasksForAdvisorRequest extends Message<ListTasksForAdvisorRequest> {
  /**
   * @generated from field: repeated protogen.TaskState taskStates = 1;
   */
  taskStates: TaskState[] = [];

  /**
   * @generated from field: repeated string familyRefs = 2;
   */
  familyRefs: string[] = [];

  /**
   * @generated from field: repeated string categories = 3;
   */
  categories: string[] = [];

  /**
   * @generated from field: repeated string priorities = 4;
   */
  priorities: string[] = [];

  constructor(data?: PartialMessage<ListTasksForAdvisorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForAdvisorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "taskStates", kind: "enum", T: proto3.getEnumType(TaskState), repeated: true },
    { no: 2, name: "familyRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "priorities", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForAdvisorRequest {
    return new ListTasksForAdvisorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForAdvisorRequest {
    return new ListTasksForAdvisorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForAdvisorRequest {
    return new ListTasksForAdvisorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForAdvisorRequest | PlainMessage<ListTasksForAdvisorRequest> | undefined, b: ListTasksForAdvisorRequest | PlainMessage<ListTasksForAdvisorRequest> | undefined): boolean {
    return proto3.util.equals(ListTasksForAdvisorRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForAdvisorResponse
 */
export class ListTasksForAdvisorResponse extends Message<ListTasksForAdvisorResponse> {
  /**
   * @generated from field: repeated protogen.Task tasks = 1;
   */
  tasks: Task[] = [];

  /**
   * @generated from field: repeated protogen.FamilyStub families = 2;
   */
  families: FamilyStub[] = [];

  constructor(data?: PartialMessage<ListTasksForAdvisorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForAdvisorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tasks", kind: "message", T: Task, repeated: true },
    { no: 2, name: "families", kind: "message", T: FamilyStub, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForAdvisorResponse {
    return new ListTasksForAdvisorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForAdvisorResponse {
    return new ListTasksForAdvisorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForAdvisorResponse {
    return new ListTasksForAdvisorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForAdvisorResponse | PlainMessage<ListTasksForAdvisorResponse> | undefined, b: ListTasksForAdvisorResponse | PlainMessage<ListTasksForAdvisorResponse> | undefined): boolean {
    return proto3.util.equals(ListTasksForAdvisorResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForMemberRequest
 */
export class ListTasksForMemberRequest extends Message<ListTasksForMemberRequest> {
  constructor(data?: PartialMessage<ListTasksForMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForMemberRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForMemberRequest {
    return new ListTasksForMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForMemberRequest {
    return new ListTasksForMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForMemberRequest {
    return new ListTasksForMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForMemberRequest | PlainMessage<ListTasksForMemberRequest> | undefined, b: ListTasksForMemberRequest | PlainMessage<ListTasksForMemberRequest> | undefined): boolean {
    return proto3.util.equals(ListTasksForMemberRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListTasksForMemberResponse
 */
export class ListTasksForMemberResponse extends Message<ListTasksForMemberResponse> {
  /**
   * @generated from field: repeated protogen.Task tasks = 1;
   */
  tasks: Task[] = [];

  constructor(data?: PartialMessage<ListTasksForMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListTasksForMemberResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tasks", kind: "message", T: Task, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTasksForMemberResponse {
    return new ListTasksForMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTasksForMemberResponse {
    return new ListTasksForMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTasksForMemberResponse {
    return new ListTasksForMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTasksForMemberResponse | PlainMessage<ListTasksForMemberResponse> | undefined, b: ListTasksForMemberResponse | PlainMessage<ListTasksForMemberResponse> | undefined): boolean {
    return proto3.util.equals(ListTasksForMemberResponse, a, b);
  }
}

