import useProtoMethod from "./useProtoMethod";
import {
  CreateTaskRequest,
  CreateTaskResponse,
  EditTaskRequest,
  EditTaskResponse,
  GetTaskRequest,
  GetTaskResponse,
  ListTasksForAdvisorRequest,
  ListTasksForAdvisorResponse,
  ListTasksForMemberRequest,
  ListTasksForMemberResponse,
  ListTasksForFamilyRequest,
  ListTasksForFamilyResponse,
} from "../protogen/tasks_service_pb";

const useCreateTask = (callback?: (r: CreateTaskResponse) => void) => {
  return useProtoMethod<CreateTaskRequest, CreateTaskResponse>(
    "CreateTask",
    CreateTaskResponse,
    { callback },
  );
};

const useUpdateTask = (callback?: (r: EditTaskResponse) => void) => {
  return useProtoMethod<EditTaskRequest, EditTaskResponse>(
    "EditTask",
    EditTaskResponse,
    { callback },
  );
};

const useGetTask = (callback?: (r: GetTaskResponse) => void) => {
  return useProtoMethod<GetTaskRequest, GetTaskResponse>(
    "GetTask",
    GetTaskResponse,
    { callback },
  );
};

const useListTasksForFamily = (
  callback?: (r: ListTasksForFamilyResponse) => void,
) => {
  return useProtoMethod<ListTasksForFamilyRequest, ListTasksForFamilyResponse>(
    "ListTasksForFamily",
    ListTasksForFamilyResponse,
    { callback },
  );
};

const useListTasksForAdvisor = (
  callback?: (r: ListTasksForAdvisorResponse) => void,
) => {
  return useProtoMethod<
    ListTasksForAdvisorRequest,
    ListTasksForAdvisorResponse
  >("ListTasksForAdvisor", ListTasksForAdvisorResponse, { callback });
};

const useListTasksForMember = (
  callback?: (r: ListTasksForMemberResponse) => void,
) => {
  return useProtoMethod<ListTasksForMemberRequest, ListTasksForMemberResponse>(
    "ListTasksForMember",
    ListTasksForMemberResponse,
    { callback },
  );
};

export {
  useCreateTask,
  useGetTask,
  useUpdateTask,
  useListTasksForFamily,
  useListTasksForAdvisor,
  useListTasksForMember,
};
