import { styled } from "@mui/system";
import { EditorContent } from "@tiptap/react";

//
// IMPORTANT NOTE:
// Any stylings which may be applicable to sent emails MUST have their styles mirrored
// in the email template. Otherwise customers may receive an email that looks different.
// resources/email-templates/tip-tap-content.html.template
//

const taskListStyles = () => ({
  // https://tiptap.dev/api/nodes/task-list
  listStyle: "none",
  padding: 0,
  margin: 0,

  p: {
    margin: 0,
  },

  li: {
    display: "flex",

    "> label": {
      flex: "0 0 auto",
      marginRight: "0.5rem",
      userSelect: "none",
    },

    "> div": {
      flex: "1 1 auto",
    },

    "ul li, ol li": {
      display: "list-item",
    },

    'ul[data-type="taskList"] > li': {
      display: "flex",
    },
  },
});

export default styled(EditorContent, {
  shouldForwardProp: (prop) => prop !== "minHeight",
})(({ minHeight = "150px" }: { minHeight?: string }) => ({
  outline: "none",
  div: {
    outline: "none",
  },
  "& .ProseMirror p": {
    margin: 0,
  },
  "& .ProseMirror image, & .ProseMirror img": {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  "& .ProseMirror": {
    outline: "none",
    minHeight: minHeight,
  },
  "& .ProseMirror-focused": {
    outline: "none",
  },
  ".ProseMirror p.is-editor-empty:first-of-type::before": {
    color: "#adb5bd",
    content: "attr(data-placeholder)",
    float: "left",
    height: 0,
    pointerEvents: "none",
  },
  '.ProseMirror ul[data-type="taskList"]': {
    ...taskListStyles(),
  },
}));
