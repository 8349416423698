import {
  Badge,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFetchAdvisorDashboard } from "../services/advisor";
import React, { ReactNode, useContext, useEffect } from "react";
import {
  Family,
  FamilyStatus,
  FetchAdvisorDashboardResponse_FamilyStatus,
} from "../protogen/advisors_service_pb";
import { styled } from "@mui/system";
import Loading from "../components/common/Loading";
import { formattedTimes } from "../common/utils";
import { ReactComponent as PhoneIcon } from "../icons/PhoneIcon.svg";
import { ReactComponent as EmailIcon } from "../icons/MailIcon.svg";
import { ReactComponent as TasksIcon } from "../icons/Menu/Tasks.svg";
import { ReactComponent as MessagesIcon } from "../icons/Menu/Messages.svg";
import SvgIcon from "@mui/material/SvgIcon";
import useIsMobile from "../components/hooks/useIsMobile";
import GridPage from "../components/layout/GridPage";
import { ReactComponent as PlusIcon } from "../icons/Plus.svg";
import { SectionHeader } from "../components/details-page/Section";
import AddFamilyDialog from "../components/family/AddFamilyDialog";
import { CurrentUserContext } from "../components/context/RequireAuth";

const AiryTableCell = styled(TableCell)({
  padding: "16px 24px",
  color: "var(--gray-900, #101828)",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
});

const AiryTableCellHeader = styled(TableCell)({
  padding: "12px 24px",
  color: "var(--gray-600, #475467)",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
});

const WrappedIconButton = ({
  badge,
  badgeUrgent = true,
  children,
  ...props
}: {
  badge: number;
  badgeUrgent?: boolean;
  children: ReactNode;
}) => {
  return (
    <IconButton color="primary" {...props}>
      <Badge
        badgeContent={badge}
        color={badgeUrgent ? "error" : "info"}
        max={99}
        sx={{
          ".MuiBadge-badge": {
            fontSize: "10px",
            minWidth: "14px",
            height: "14px",
            padding: "0 4px",
          },
        }}
      >
        <SvgIcon
          sx={{
            fontSize: "20px",
            path: {
              stroke: "#475467",
            },
          }}
        >
          {children}
        </SvgIcon>
      </Badge>
    </IconButton>
  );
};

const FamilyRow = ({
  family,
  familyStatus,
}: {
  family: Family;
  familyStatus: FetchAdvisorDashboardResponse_FamilyStatus;
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const time = familyStatus.lastActivitySec
    ? formattedTimes(new Date(Number(familyStatus.lastActivitySec) * 1000))
    : "N/A";
  const familyStats = [
    <WrappedIconButton
      key={0}
      aria-label="tasks"
      badge={familyStatus.openTasks}
      badgeUrgent={false}
    >
      <TasksIcon color="action" />
    </WrappedIconButton>,
    <WrappedIconButton
      key={1}
      aria-label="messages"
      badge={familyStatus.unreadMessages}
    >
      <MessagesIcon color="action" />
    </WrappedIconButton>,
    <WrappedIconButton
      key={2}
      aria-label="email"
      badge={familyStatus.unreadEmails}
    >
      <EmailIcon color="action" />
    </WrappedIconButton>,
    <WrappedIconButton
      key={3}
      aria-label="phone"
      badge={familyStatus.missedCalls}
    >
      <PhoneIcon color="action" />
    </WrappedIconButton>,
  ];
  return (
    <>
      <TableRow
        hover
        onClick={() => {
          navigate(`/families/${encodeURIComponent(family.ref)}`);
        }}
        sx={{ cursor: "pointer" }}
      >
        <AiryTableCell sx={{}}>{family.name}</AiryTableCell>
        <AiryTableCell>{family.location}</AiryTableCell>
        <AiryTableCell>{time}</AiryTableCell>
        {!isMobile && <AiryTableCell>{familyStats}</AiryTableCell>}
      </TableRow>
      {isMobile && (
        <TableRow
          onClick={() => {
            navigate(`/families/${family.ref}`);
          }}
          sx={{}}
        >
          <AiryTableCell colSpan={3} sx={{ textAlign: "center" }}>
            {familyStats}
          </AiryTableCell>
        </TableRow>
      )}
    </>
  );
};

export default () => {
  const isMobile = useIsMobile();
  const currentUser = useContext(CurrentUserContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const { loading, data, request, error } = useFetchAdvisorDashboard();
  useEffect(() => {
    request();
  }, []);
  const myFamilies = (data?.families || []).sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const _isActiveAdvisorOf = (f: Family): boolean =>
    f.status === FamilyStatus.ACTIVE && f.advisorRef === currentUser.ref;
  const myActiveFamilies = myFamilies.filter(_isActiveAdvisorOf);
  const allOtherFamilies = myFamilies.filter(
    (family) => !_isActiveAdvisorOf(family),
  );
  const familyStatus = new Map(
    (data?.familyStatus || []).map((s) => [s.familyRef, s]),
  );
  return (
    <GridPage>
      <Box
        sx={{
          flex: "1 1 100%",
          marginBottom: "32px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{}} variant="h4" id="tableTitle" component="div">
          Families
        </Typography>
        <Button startIcon={<PlusIcon />} onClick={() => setModalOpen(true)}>
          Add Family
        </Button>
      </Box>
      <Typography
        sx={{
          flex: "1 1 100%",
          marginBottom: "32px",
        }}
        variant="h4"
        id="tableTitle"
        component="div"
      ></Typography>
      <Box
        sx={{
          width: "100%",
          borderRadius: "12px",
          border: "1px solid var(--gray-200, #EAECF0)",
          background: "var(--base-white, #FFF)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            padding: "20px 24px 0px 24px",
            margin: "0",
          }}
        >
          <SectionHeader title={"My families"} />
        </Box>
        {error || loading || !data ? (
          <Loading />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <AiryTableCellHeader>Family name</AiryTableCellHeader>
                  <AiryTableCellHeader>Location</AiryTableCellHeader>
                  <AiryTableCellHeader>Last activity</AiryTableCellHeader>
                  {!isMobile && (
                    <AiryTableCellHeader sx={{ paddingLeft: "70px" }}>
                      Actions
                    </AiryTableCellHeader>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {myActiveFamilies &&
                  myActiveFamilies.map((family) => (
                    <FamilyRow
                      key={family.ref}
                      family={family}
                      familyStatus={familyStatus.get(family.ref)!}
                    />
                  ))}
                {allOtherFamilies.length ? (
                  <>
                    <TableRow>
                      <AiryTableCellHeader
                        component="th"
                        scope="row"
                        colSpan={4}
                        sx={{ paddingTop: "24px" }}
                      >
                        Other Families & Contacts
                      </AiryTableCellHeader>
                    </TableRow>
                    {allOtherFamilies.map((family) => (
                      <FamilyRow
                        key={family.ref}
                        family={family}
                        familyStatus={familyStatus.get(family.ref)!}
                      />
                    ))}
                  </>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <AddFamilyDialog open={modalOpen} onClose={() => setModalOpen(false)} />
    </GridPage>
  );
};
