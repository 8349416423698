// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/secrets_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { SecretStub } from "./secrets_pb.ts";

/**
 * @generated from message protogen.CreateFamilySecretRequest
 */
export class CreateFamilySecretRequest extends Message<CreateFamilySecretRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string valueType = 3;
   */
  valueType = "";

  /**
   *  bool returnUpdateUrl = 5;
   *  bool returnShareUrl = 6;
   *
   * @generated from field: string value = 4;
   */
  value = "";

  constructor(data?: PartialMessage<CreateFamilySecretRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilySecretRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "valueType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilySecretRequest {
    return new CreateFamilySecretRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilySecretRequest {
    return new CreateFamilySecretRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilySecretRequest {
    return new CreateFamilySecretRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilySecretRequest | PlainMessage<CreateFamilySecretRequest> | undefined, b: CreateFamilySecretRequest | PlainMessage<CreateFamilySecretRequest> | undefined): boolean {
    return proto3.util.equals(CreateFamilySecretRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilySecretResponse
 */
export class CreateFamilySecretResponse extends Message<CreateFamilySecretResponse> {
  /**
   * @generated from field: protogen.SecretStub secret = 1;
   */
  secret?: SecretStub;

  constructor(data?: PartialMessage<CreateFamilySecretResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilySecretResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "secret", kind: "message", T: SecretStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilySecretResponse {
    return new CreateFamilySecretResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilySecretResponse {
    return new CreateFamilySecretResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilySecretResponse {
    return new CreateFamilySecretResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilySecretResponse | PlainMessage<CreateFamilySecretResponse> | undefined, b: CreateFamilySecretResponse | PlainMessage<CreateFamilySecretResponse> | undefined): boolean {
    return proto3.util.equals(CreateFamilySecretResponse, a, b);
  }
}

/**
 * @generated from message protogen.DeleteSecretRequest
 */
export class DeleteSecretRequest extends Message<DeleteSecretRequest> {
  /**
   * @generated from field: string secretRef = 1;
   */
  secretRef = "";

  constructor(data?: PartialMessage<DeleteSecretRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteSecretRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "secretRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSecretRequest {
    return new DeleteSecretRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSecretRequest {
    return new DeleteSecretRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSecretRequest {
    return new DeleteSecretRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSecretRequest | PlainMessage<DeleteSecretRequest> | undefined, b: DeleteSecretRequest | PlainMessage<DeleteSecretRequest> | undefined): boolean {
    return proto3.util.equals(DeleteSecretRequest, a, b);
  }
}

/**
 * @generated from message protogen.DeleteSecretResponse
 */
export class DeleteSecretResponse extends Message<DeleteSecretResponse> {
  constructor(data?: PartialMessage<DeleteSecretResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteSecretResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSecretResponse {
    return new DeleteSecretResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSecretResponse {
    return new DeleteSecretResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSecretResponse {
    return new DeleteSecretResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSecretResponse | PlainMessage<DeleteSecretResponse> | undefined, b: DeleteSecretResponse | PlainMessage<DeleteSecretResponse> | undefined): boolean {
    return proto3.util.equals(DeleteSecretResponse, a, b);
  }
}

/**
 * @generated from message protogen.RevealSecretRequest
 */
export class RevealSecretRequest extends Message<RevealSecretRequest> {
  /**
   * @generated from field: string secretRef = 1;
   */
  secretRef = "";

  constructor(data?: PartialMessage<RevealSecretRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RevealSecretRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "secretRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RevealSecretRequest {
    return new RevealSecretRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RevealSecretRequest {
    return new RevealSecretRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RevealSecretRequest {
    return new RevealSecretRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RevealSecretRequest | PlainMessage<RevealSecretRequest> | undefined, b: RevealSecretRequest | PlainMessage<RevealSecretRequest> | undefined): boolean {
    return proto3.util.equals(RevealSecretRequest, a, b);
  }
}

/**
 * @generated from message protogen.RevealSecretResponse
 */
export class RevealSecretResponse extends Message<RevealSecretResponse> {
  /**
   * @generated from field: protogen.SecretStub secret = 1;
   */
  secret?: SecretStub;

  /**
   * @generated from field: string secretValue = 2;
   */
  secretValue = "";

  constructor(data?: PartialMessage<RevealSecretResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RevealSecretResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "secret", kind: "message", T: SecretStub },
    { no: 2, name: "secretValue", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RevealSecretResponse {
    return new RevealSecretResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RevealSecretResponse {
    return new RevealSecretResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RevealSecretResponse {
    return new RevealSecretResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RevealSecretResponse | PlainMessage<RevealSecretResponse> | undefined, b: RevealSecretResponse | PlainMessage<RevealSecretResponse> | undefined): boolean {
    return proto3.util.equals(RevealSecretResponse, a, b);
  }
}

