import { useParams } from "react-router-dom";
import {
  Box,
  Skeleton,
  Button,
  Link,
  Typography,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DocumentViewer from "../components/editor/DocumentViewer";
import GridPage from "../components/layout/GridPage";
import { useFetchPublicAdvisorProfile } from "../services/public";
import {
  UserAvatar,
  DefaultAvatar,
} from "../components/common/CurrentUserAvatar";
import AdvisorDetails from "../components/advisor/AdvisorDetails";
import useIsMobile from "../components/hooks/useIsMobile";
import MemberInquiry from "../components/advisor/MemberInquiry";
import { ReactComponent as FayeIcon } from "../icons/FayeLight.svg";

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const AdvisorStaticContent = ({ className }: { className: string }) => {
  const header = "What can a Faye Family Assistant do?";
  const description = (
    <>
      Your local Family Assistant will help with the strategic tasks that take
      up too much time and brainspace.
      <br />
      <br />
      Here are some quick ideas to get you started:
    </>
  );
  const listItems = [
    "Register the kids for activities",
    "Meal plan and order grocery delivery",
    "Plan your next family vacation",
    "Source home contractors and schedule visits",
    "Send flowers for your bestie's birthday",
  ];
  return (
    <Box className={className}>
      <Typography
        sx={{
          fontFamily: "SourceSerifPro",
          fontWeight: "600",
          fontSize: "24px",
        }}
      >
        {header}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
        }}
      >
        {description}
      </Typography>
      <List sx={{ listStyleType: "disc" }}>
        {listItems.map((item) => (
          <ListItem
            sx={{ display: "list-item", marginLeft: 4, padding: "0px" }}
            key={item}
          >
            {item}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const Sidebar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "70px 70px 0px 0px",
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "1px",
          backgroundColor: "#D2D2D2",
        }}
      />
      <Box sx={{ paddingLeft: "35px" }}>
        <AdvisorStaticContent className={""} />
      </Box>
    </Box>
  );
};

const FooterAdvisorStaticContent = styled(AdvisorStaticContent)({
  padding: "35px 0px",
  maxWidth: "750px",
});

export default () => {
  let params = useParams();
  const isMobile = useIsMobile();
  useState<boolean>(false);
  const { request, data, loading, error } = useFetchPublicAdvisorProfile();

  useEffect(() => {
    const profilePath = params.profilePath!;
    request({ profilePath });
  }, [params.advisorRef]);

  const advisor = data?.advisor;
  return (
    <GridPage sx={{ padding: "0px" }}>
      <Grid
        container
        sx={{
          height: "115px",
          backgroundColor: "#198282",
        }}
      >
        <Grid
          item
          xs={isMobile ? 6 : 8}
          sx={{ paddingLeft: isMobile ? "35px" : "70px" }}
        >
          <CenteredBox
            sx={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                color: "#FFFFFF",
                width: "100%",
                maxWidth: "750px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FayeIcon />
              {!isMobile && (
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, color: "#B5EEE2" }}
                >
                  Let's get things done.
                </Typography>
              )}
            </Box>
          </CenteredBox>
        </Grid>
        <Grid
          item
          xs={isMobile ? 6 : 4}
          sx={{ paddingRight: isMobile ? "35px" : "70px" }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Button
              sx={{ backgroundColor: isMobile ? "#198282" : "#1B6E6E" }}
              variant={isMobile ? "text" : "contained"}
              aria-label="About Faye"
            >
              <Link
                href="https://findfaye.com"
                target="_blank"
                underline="none"
                sx={{
                  color: "#FFFFFF",
                }}
              >
                About Faye
              </Link>
              {!isMobile && (
                <ArrowForwardIcon sx={{ marginLeft: "5px" }} fontSize="small" />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
      {error && <div>Not Found</div>}
      {!error && (
        <Grid container>
          {/* It should span the full width on mobile (12).  The other grid panel is removed. */}
          <Grid item xs={isMobile ? 12 : 8}>
            <CenteredBox
              sx={{
                padding: "70px",
                paddingRight: !isMobile ? "35px" : "70px",
              }}
            >
              <Box
                sx={{
                  maxWidth: "750px",
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  ...(isMobile
                    ? {
                        marginBottom: "10px",
                      }
                    : {
                        marginBottom: "44px",
                      }),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    ...(isMobile
                      ? {
                          marginBottom: "10px",
                          gap: "20px",
                          flexDirection: "column",
                          alignItems: "center",
                        }
                      : {
                          marginBottom: "35px",
                          gap: "50px",
                          flexDirection: "row",
                        }),
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      maxWidth: "200px",
                      width: "100%",
                    }}
                  >
                    {advisor ? (
                      <UserAvatar user={advisor} size={200} />
                    ) : (
                      <DefaultAvatar size={200} />
                    )}
                  </Box>
                  <AdvisorDetails advisor={advisor} loading={loading} />
                </Box>
                <Box
                  sx={{
                    fontFamily: "SourceSerifPro",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  About me
                </Box>
                {loading ? (
                  <Skeleton width="80%" />
                ) : (
                  <DocumentViewer
                    sx={{ marginBottom: "44px" }}
                    content={
                      advisor && advisor?.blurbContent
                        ? JSON.parse(advisor?.blurbContent)
                        : ""
                    }
                  />
                )}
                <MemberInquiry />
              </Box>
              {isMobile && <FooterAdvisorStaticContent className={""} />}
            </CenteredBox>
          </Grid>
          {!isMobile && (
            <Grid item xs={4}>
              <Sidebar />
            </Grid>
          )}
        </Grid>
      )}
    </GridPage>
  );
};
