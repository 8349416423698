import useProtoMethod from "./useProtoMethod";
import {
  GPTExtractionFromSegmentsPromptRequest,
  GPTExtractionFromSegmentsPromptResponse,
  GPTExtractionFromSegmentsRequest,
  GPTExtractionFromSegmentsResponse,
  ListExtractionFunctionsRequest,
  ListExtractionFunctionsResponse,
} from "../protogen/extraction_service_pb";
import { getInferenceDomain } from "../constants";

const useSegmentExtraction = (
  callback?: (r: GPTExtractionFromSegmentsResponse) => void,
) => {
  return useProtoMethod<
    GPTExtractionFromSegmentsRequest,
    GPTExtractionFromSegmentsResponse
  >("GPTExtractionFromSegments", GPTExtractionFromSegmentsResponse, {
    baseUrl: getInferenceDomain(),
    callback,
  });
};

const useListExtractionFunction = (
  callback?: (r: ListExtractionFunctionsResponse) => void,
) => {
  return useProtoMethod<
    ListExtractionFunctionsRequest,
    ListExtractionFunctionsResponse
  >("ListExtractionFunctions", ListExtractionFunctionsResponse, {
    baseUrl: getInferenceDomain(),
    callback,
  });
};

const usePromptExtraction = (
  callback?: (r: GPTExtractionFromSegmentsPromptResponse) => void,
) => {
  return useProtoMethod<
    GPTExtractionFromSegmentsPromptRequest,
    GPTExtractionFromSegmentsPromptResponse
  >(
    "GPTExtractionFromSegmentsPrompt",
    GPTExtractionFromSegmentsPromptResponse,
    {
      baseUrl: getInferenceDomain(),
      callback,
    },
  );
};

export { useSegmentExtraction, useListExtractionFunction, usePromptExtraction };
