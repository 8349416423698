import useProtoMethod from "./useProtoMethod";
import {
  AdminListAllAdvisorsRequest,
  AdminListAllAdvisorsResponse,
  CreateAdvisorRequest,
  CreateAdvisorResponse,
  GenerateAdvisorContactsRequest,
  GenerateAdvisorContactsResponse,
  ListAllFamiliesRequest,
  ListAllFamiliesResponse,
  ListAuditRecordsRequest,
  ListAuditRecordsResponse,
  UpdateUserRolesRequest,
  UpdateUserRolesResponse,
} from "../protogen/admin_service_pb";

const useListAllFamilies = () => {
  return useProtoMethod<ListAllFamiliesRequest, ListAllFamiliesResponse>(
    "ListAllFamilies",
    ListAllFamiliesResponse,
  );
};

const useListAuditRecords = () => {
  return useProtoMethod<ListAuditRecordsRequest, ListAuditRecordsResponse>(
    "ListAuditRecords",
    ListAuditRecordsResponse,
  );
};

const useCreateAdvisor = (callback?: (r: CreateAdvisorResponse) => void) => {
  return useProtoMethod<CreateAdvisorRequest, CreateAdvisorResponse>(
    "CreateAdvisor",
    CreateAdvisorResponse,
    { callback },
  );
};

const useUpdateRoles = (callback?: (r: UpdateUserRolesResponse) => void) => {
  return useProtoMethod<UpdateUserRolesRequest, UpdateUserRolesResponse>(
    "UpdateUserRoles",
    UpdateUserRolesResponse,
    { callback },
  );
};

const useAdminListAllAdvisors = (
  callback?: (r: AdminListAllAdvisorsResponse) => void,
) => {
  return useProtoMethod<
    AdminListAllAdvisorsRequest,
    AdminListAllAdvisorsResponse
  >("AdminListAllAdvisors", AdminListAllAdvisorsResponse, { callback });
};

const useGenerateAdvisorContacts = (
  callback?: (r: GenerateAdvisorContactsResponse) => void,
) => {
  return useProtoMethod<
    GenerateAdvisorContactsRequest,
    GenerateAdvisorContactsResponse
  >("GenerateAdvisorContacts", GenerateAdvisorContactsResponse, { callback });
};

export {
  useListAllFamilies,
  useListAuditRecords,
  useAdminListAllAdvisors,
  useCreateAdvisor,
  useUpdateRoles,
  useGenerateAdvisorContacts,
};
