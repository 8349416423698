import useProtoMethod from "./useProtoMethod";
import {
  SearchRequest,
  SearchResponse,
  SemanticQueryRequest,
  SemanticQueryResponse,
  SemanticRetrievalRequest,
  SemanticRetrievalResponse,
} from "../protogen/search_service_pb";
import { getInferenceDomain } from "../constants";

const useSearch = (callback?: (r: SearchResponse) => void) => {
  return useProtoMethod<SearchRequest, SearchResponse>(
    "Search",
    SearchResponse,
    {
      baseUrl: getInferenceDomain(),
      callback,
    },
  );
};

const useSemanticQuery = (callback?: (r: SemanticQueryResponse) => void) => {
  return useProtoMethod<SemanticQueryRequest, SemanticQueryResponse>(
    "SemanticQuery",
    SemanticQueryResponse,
    {
      baseUrl: getInferenceDomain(),
      callback,
    },
  );
};

const useSemanticRetrieval = (
  callback?: (r: SemanticRetrievalResponse) => void,
) => {
  return useProtoMethod<SemanticRetrievalRequest, SemanticRetrievalResponse>(
    "SemanticRetrieval",
    SemanticRetrievalResponse,
    {
      baseUrl: getInferenceDomain(),
      callback,
    },
  );
};

export { useSearch, useSemanticQuery, useSemanticRetrieval };
