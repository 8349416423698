import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { Box } from "@mui/material";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: "AlbertSans, SourceSerifPro, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'AlbertSans';
          src: url('/assets/fonts/AlbertSans-VariableFont_wght.ttf') format('truetype');
          /* Add more src declarations for different font formats if available */
          font-weight: 100 1000;
          font-style: normal;
        }
        @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 200;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-ExtraLight.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-ExtraLight.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 200;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-ExtraLightIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-ExtraLightIt.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Light.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Light.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 300;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-LightIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-LightIt.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Regular.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Regular.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 400;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-It.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-It.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Semibold.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Semibold.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 600;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-SemiboldIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-SemiboldIt.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Bold.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Bold.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 700;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-BoldIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-BoldIt.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 900;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Black.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Black.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 900;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-BlackIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-BlackIt.otf.woff') format('woff');
      }
      `,
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: "100px",
          textTransform: "none",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeMedium: {
          padding: "8px 24px",
        },
        outlined: {
          border: "2px solid #198282",
          "&:hover": {
            border: "2px solid #198282",
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: "#FDFAF7",
    },
    primary: {
      main: "#198282",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
