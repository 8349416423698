// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/search_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message protogen.RetrievalResult
 */
export class RetrievalResult extends Message<RetrievalResult> {
  /**
   * @generated from field: float score = 1;
   */
  score = 0;

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * @generated from field: string entityType = 3;
   */
  entityType = "";

  /**
   * @generated from field: string entityRef = 4;
   */
  entityRef = "";

  /**
   * @generated from field: string familyRef = 5;
   */
  familyRef = "";

  /**
   * @generated from field: string sourceName = 6;
   */
  sourceName = "";

  /**
   * @generated from field: int64 indexedSec = 7;
   */
  indexedSec = protoInt64.zero;

  /**
   * @generated from field: repeated protogen.RetrievalResult.Metadata metadata = 8;
   */
  metadata: RetrievalResult_Metadata[] = [];

  constructor(data?: PartialMessage<RetrievalResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RetrievalResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "entityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sourceName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "indexedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "metadata", kind: "message", T: RetrievalResult_Metadata, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetrievalResult {
    return new RetrievalResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetrievalResult {
    return new RetrievalResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetrievalResult {
    return new RetrievalResult().fromJsonString(jsonString, options);
  }

  static equals(a: RetrievalResult | PlainMessage<RetrievalResult> | undefined, b: RetrievalResult | PlainMessage<RetrievalResult> | undefined): boolean {
    return proto3.util.equals(RetrievalResult, a, b);
  }
}

/**
 * @generated from message protogen.RetrievalResult.Metadata
 */
export class RetrievalResult_Metadata extends Message<RetrievalResult_Metadata> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<RetrievalResult_Metadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RetrievalResult.Metadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetrievalResult_Metadata {
    return new RetrievalResult_Metadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetrievalResult_Metadata {
    return new RetrievalResult_Metadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetrievalResult_Metadata {
    return new RetrievalResult_Metadata().fromJsonString(jsonString, options);
  }

  static equals(a: RetrievalResult_Metadata | PlainMessage<RetrievalResult_Metadata> | undefined, b: RetrievalResult_Metadata | PlainMessage<RetrievalResult_Metadata> | undefined): boolean {
    return proto3.util.equals(RetrievalResult_Metadata, a, b);
  }
}

/**
 * @generated from message protogen.SearchFilter
 */
export class SearchFilter extends Message<SearchFilter> {
  /**
   * @generated from field: string field = 1;
   */
  field = "";

  /**
   * @generated from field: string operator = 2;
   */
  operator = "";

  /**
   * @generated from field: string value = 3;
   */
  value = "";

  constructor(data?: PartialMessage<SearchFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SearchFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "operator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchFilter {
    return new SearchFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchFilter {
    return new SearchFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchFilter {
    return new SearchFilter().fromJsonString(jsonString, options);
  }

  static equals(a: SearchFilter | PlainMessage<SearchFilter> | undefined, b: SearchFilter | PlainMessage<SearchFilter> | undefined): boolean {
    return proto3.util.equals(SearchFilter, a, b);
  }
}

/**
 * @generated from message protogen.SemanticQueryRequest
 */
export class SemanticQueryRequest extends Message<SemanticQueryRequest> {
  /**
   * @generated from field: string queryText = 1;
   */
  queryText = "";

  /**
   * @generated from field: string retrievalQueryText = 2;
   */
  retrievalQueryText = "";

  constructor(data?: PartialMessage<SemanticQueryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SemanticQueryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "queryText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "retrievalQueryText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SemanticQueryRequest {
    return new SemanticQueryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SemanticQueryRequest {
    return new SemanticQueryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SemanticQueryRequest {
    return new SemanticQueryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SemanticQueryRequest | PlainMessage<SemanticQueryRequest> | undefined, b: SemanticQueryRequest | PlainMessage<SemanticQueryRequest> | undefined): boolean {
    return proto3.util.equals(SemanticQueryRequest, a, b);
  }
}

/**
 * @generated from message protogen.SemanticQueryResponse
 */
export class SemanticQueryResponse extends Message<SemanticQueryResponse> {
  /**
   * @generated from field: string queryResult = 1;
   */
  queryResult = "";

  /**
   * @generated from field: string retrievalQuery = 2;
   */
  retrievalQuery = "";

  /**
   * @generated from field: repeated protogen.RetrievalResult retrievalResults = 3;
   */
  retrievalResults: RetrievalResult[] = [];

  constructor(data?: PartialMessage<SemanticQueryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SemanticQueryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "queryResult", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "retrievalQuery", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "retrievalResults", kind: "message", T: RetrievalResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SemanticQueryResponse {
    return new SemanticQueryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SemanticQueryResponse {
    return new SemanticQueryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SemanticQueryResponse {
    return new SemanticQueryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SemanticQueryResponse | PlainMessage<SemanticQueryResponse> | undefined, b: SemanticQueryResponse | PlainMessage<SemanticQueryResponse> | undefined): boolean {
    return proto3.util.equals(SemanticQueryResponse, a, b);
  }
}

/**
 * @generated from message protogen.SemanticRetrievalRequest
 */
export class SemanticRetrievalRequest extends Message<SemanticRetrievalRequest> {
  /**
   * @generated from field: string retrievalQueryText = 1;
   */
  retrievalQueryText = "";

  constructor(data?: PartialMessage<SemanticRetrievalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SemanticRetrievalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "retrievalQueryText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SemanticRetrievalRequest {
    return new SemanticRetrievalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SemanticRetrievalRequest {
    return new SemanticRetrievalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SemanticRetrievalRequest {
    return new SemanticRetrievalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SemanticRetrievalRequest | PlainMessage<SemanticRetrievalRequest> | undefined, b: SemanticRetrievalRequest | PlainMessage<SemanticRetrievalRequest> | undefined): boolean {
    return proto3.util.equals(SemanticRetrievalRequest, a, b);
  }
}

/**
 * @generated from message protogen.SemanticRetrievalResponse
 */
export class SemanticRetrievalResponse extends Message<SemanticRetrievalResponse> {
  /**
   * @generated from field: string retrievalQuery = 1;
   */
  retrievalQuery = "";

  /**
   * @generated from field: repeated protogen.RetrievalResult retrievalResults = 2;
   */
  retrievalResults: RetrievalResult[] = [];

  constructor(data?: PartialMessage<SemanticRetrievalResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SemanticRetrievalResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "retrievalQuery", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "retrievalResults", kind: "message", T: RetrievalResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SemanticRetrievalResponse {
    return new SemanticRetrievalResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SemanticRetrievalResponse {
    return new SemanticRetrievalResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SemanticRetrievalResponse {
    return new SemanticRetrievalResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SemanticRetrievalResponse | PlainMessage<SemanticRetrievalResponse> | undefined, b: SemanticRetrievalResponse | PlainMessage<SemanticRetrievalResponse> | undefined): boolean {
    return proto3.util.equals(SemanticRetrievalResponse, a, b);
  }
}

/**
 * @generated from message protogen.SearchRequest
 */
export class SearchRequest extends Message<SearchRequest> {
  /**
   * @generated from field: string queryText = 1;
   */
  queryText = "";

  /**
   * @generated from field: repeated protogen.SearchFilter filters = 2;
   */
  filters: SearchFilter[] = [];

  /**
   * @generated from field: bool adminSearchMode = 3;
   */
  adminSearchMode = false;

  constructor(data?: PartialMessage<SearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "queryText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filters", kind: "message", T: SearchFilter, repeated: true },
    { no: 3, name: "adminSearchMode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchRequest {
    return new SearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchRequest {
    return new SearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchRequest {
    return new SearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchRequest | PlainMessage<SearchRequest> | undefined, b: SearchRequest | PlainMessage<SearchRequest> | undefined): boolean {
    return proto3.util.equals(SearchRequest, a, b);
  }
}

/**
 * @generated from message protogen.SearchResponse
 */
export class SearchResponse extends Message<SearchResponse> {
  /**
   * @generated from field: repeated protogen.RetrievalResult results = 3;
   */
  results: RetrievalResult[] = [];

  constructor(data?: PartialMessage<SearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "results", kind: "message", T: RetrievalResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchResponse {
    return new SearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchResponse {
    return new SearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchResponse {
    return new SearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchResponse | PlainMessage<SearchResponse> | undefined, b: SearchResponse | PlainMessage<SearchResponse> | undefined): boolean {
    return proto3.util.equals(SearchResponse, a, b);
  }
}

