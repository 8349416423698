import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet, useLocation } from "react-router-dom";
import { AppBar } from "@mui/material";
import RequireAuth from "../context/RequireAuth";
import NotificationPrompt from "../prompts/NotificationPrompt";
import { AddCallContext } from "../context/AddCallContext";
import ResponsiveDrawer from "./ResponsiveDrawer";
import HttpErrorProvider from "../context/HttpErrorProvider";
import useIsMobile from "../hooks/useIsMobile";
import CloseIcon from "@mui/icons-material/Close";
import LogoButton from "./LogoButton";
import SearchContextProvider from "../context/SearchContextProvider";
import CheckTerms from "../context/CheckTerms";
import { AccountType } from "../../protogen/auth_service_pb";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerContent = ({
  isPersistent,
  open,
  setOpen,
  content,
}: {
  isPersistent: boolean;
  open: boolean;
  setOpen: (b: boolean) => void;
  content: React.ReactNode;
}) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  // Bad regex - fix!
  const [, selectedTab] = location.pathname.match(
    "(/families|/tasks|/settings|/tools|/inbox|/forum)/?.*$",
  ) || [undefined, "families"];
  const setClosed = () => setOpen(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        paddingBottom: "10px",
        backgroundColor: "#252525",
      }}
    >
      {typeof content === "function" &&
        content({ open, isPersistent, selectedTab, setClosed, isMobile })}
    </Box>
  );
};

type NavigationLayoutProps = {
  drawerContent: React.ReactNode;
  loginRedirectPath: string;
  tosAccountType?: AccountType;
};

export default ({
  drawerContent,
  loginRedirectPath,
  tosAccountType,
}: NavigationLayoutProps) => {
  const [open, setOpen] = React.useState(false);
  const drawerWidth = 240;
  const persistentNavWidth = 83;
  const useAppBar = useIsMobile();
  return (
    <RequireAuth loginRedirectPath={loginRedirectPath}>
      <HttpErrorProvider>
        {tosAccountType && <CheckTerms accountType={tosAccountType} />}
        <NotificationPrompt />
        <AddCallContext>
          <SearchContextProvider>
            <CssBaseline />
            {useAppBar && (
              <AppBar
                sx={{
                  // backgroundColor: '#252525',
                  // Making this change so it fades to black at the top for iOS web apps.
                  background: "linear-gradient(to top, #252525, black)",
                }}
              >
                <Toolbar>
                  <LogoButton />
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpen(true)}
                    sx={{
                      ...(open && { display: "none" }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
            )}
            <ResponsiveDrawer
              open={open}
              setOpen={(o) => setOpen(o)}
              drawerWidth={drawerWidth}
            >
              <DrawerContent
                isPersistent={!useAppBar}
                open={open}
                setOpen={(o) => setOpen(o)}
                content={drawerContent}
              />
            </ResponsiveDrawer>
            {open && (
              <IconButton
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 100000,
                }}
                onClick={() => setOpen(false)}
              >
                <CloseIcon
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            )}
            <div
              style={{
                marginLeft: `${useAppBar ? 0 : persistentNavWidth}px`,
              }}
            >
              {/* Needed to make space for app bar. */}
              {useAppBar && <DrawerHeader />}
              <Outlet />
            </div>
          </SearchContextProvider>
        </AddCallContext>
      </HttpErrorProvider>
    </RequireAuth>
  );
};
